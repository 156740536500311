import React, { useEffect, useState } from "react";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { IoMdDownload } from "react-icons/io";
import { getReq } from "../../../api";
import toastNotification from "../../../../helperFunc/toast";
import { Card } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Loading from "../../../components/Loading";

const SelectedCandiadtes = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCandidateData, setSelectedCandidateData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const getCandidatesData = await getReq("/company/selected-candidates");
        if (getCandidatesData.status === 1) {
          setSelectedCandidateData(getCandidatesData.data);
          setIsLoading(false);
        } else {
          toastNotification("error", getCandidatesData.message);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {!isLoading ? (
        <div className="row">
          {selectedCandidateData.length <= 0 && <div>No records found.</div>}
          {selectedCandidateData.length > 0 &&
            selectedCandidateData.map((candidate, i) => {
              return (
                <div
                  className="profile-card-parent"
                  key={`candidate-${i}`}
                >
                  <div className="profile-card">
                    <div className="image">
                      <img
                        src={toAbsoluteUrl("/media/users/default.jpg")}
                        alt="Photo"
                        className="profile-img"
                      />
                    </div>
                    <div className="text-data">
                      <span className="name">{candidate?.vendorDetails?.name}</span>
                      <ul className="list-group">
                        <li className="list-group-item list-group-items">
                          <strong>Job Title : </strong>
                          <span className='consultant-job'>{candidate?.requirementDetails?.requirementName}</span>
                        </li>
                        <li className="list-group-item list-group-items">
                          <strong>Job Type : </strong>
                          <span className='consultant-job'> {candidate?.requirementDetails?.requirementType}</span>
                        </li>
                        <li className="list-group-item list-group-items">
                          <strong>Job Location : </strong>
                          <span className='consultant-job'> {candidate?.requirementDetails?.jobLocation}</span>
                        </li>
                        <li className="list-group-item list-group-items">
                          <strong>Final Budget : </strong>
                          <span className='consultant-job'> <FaIndianRupeeSign /> {candidate.finalBudget}</span>
                        </li>
                      </ul>
                    </div>
                    <div className="buttons">
                      <button className="button"><IoMdDownload /> Resume</button>
                      <button className="button"><IoMdDownload /> SOW</button>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default SelectedCandiadtes;
