import React from "react";
import moment from "moment";
import { Modal, Table } from "react-bootstrap";

const TimesheetViewModal = ({ show, onHide, timesheet }) => {
  if (!timesheet) {
    return null;
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>View Tasks - {moment(timesheet.date).format("DD/MM/YYYY")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Task Name</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Task Description</th>
            </tr>
          </thead>
          <tbody>
            {timesheet.tasks.map((task, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{task.taskName}</td>
                <td>{new Date(task.startTime).toLocaleTimeString()}</td>
                <td>{new Date(task.endTime).toLocaleTimeString()}</td>
                <td>{task.taskDesc}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex flex-wrap justify-content-end mt-4" onClick={onHide}>
          <button
            className="btn btn-primary font-weight-bold px-9 py-3"
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default TimesheetViewModal;
