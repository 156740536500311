import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import ReusableTable from "../../../components/ReusableTable";
import Loading from "../../../components/Loading";
import toastNotification from "../../../../helperFunc/toast";
import { postReq } from "../../../api";
import AddTags from "../../../components/AddTagsModal";
import UpdateTags from "../../../components/UpdateTagsModal";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import { showConfirmDialog } from "../../../../helperFunc/helper";

const TechnologyNiche = () => {
  const [showNewTechModal, setNewTechShowModal] = useState(false);
  const [technologyNiche, setTechnologyNiche] = useState("");
  const [requiredTechnology, setRequiredTechnology] = useState("");
  const [isLoading, setIsLoading] = useState();
  const [showModalRequiredTech, setShowModalRequiredTech] = useState();
  const [showModalTechNiche, setShowModalTechNiche] = useState();

  const [showUpdateRequiredTechModal, setShowUpdateRequiredTechModal] = useState();
  const [showUpdateTechNicheModal, setShowUpdateTechNicheModal] = useState();

  const [requiredTechData, setRequiredTechData] = useState();
  const [techNicheData, setTechNicheData] = useState();
  const [filters, setFilters] = useState({
    options: {
      page: 1,
      limit: 10,
    },
    query: {
      search: "",
    },
  });
  const [techFilters, setTechFilters] = useState({
    options: {
      page: 1,
      limit: 10,
    },
    query: {
      search: "",
    },
  });

  const addNewTechNiche = () => {
    setShowModalTechNiche(true);
  }

  const addNewRequiredTech = () => {
    setShowModalRequiredTech(true)
  }

  const closeTechNiche = () => {
    setShowModalTechNiche(false)
  }

  const closeRequiredTech = () => {
    setShowModalRequiredTech(false)
  }
  const updateNewTechNiche = (data) => {
    console.log('data tech niche', data)
    setShowUpdateTechNicheModal(true);
    setTechNicheData(data);
  }

  const updateNewRequiredTech = (data) => {
    setShowUpdateRequiredTechModal(true);
    setRequiredTechData(data);
  }

  const closeUpdateTechNiche = () => {
    setShowUpdateTechNicheModal(false)
  }

  const closeUpdateRequiredTech = () => {
    setShowUpdateRequiredTechModal(false)
  }

  const fetchTags = async (isRequiredTech) => {
    try {
      let url;
      if (isRequiredTech) {
        url = "/common/list-required-technology"
      } else {
        url = "/common/list-tech-niche"
      }

      let data = await postReq(url, { limit: 100, page: 1 });
      if (data.status == 1) {
        if (isRequiredTech) {
          setRequiredTechnology(data.data);
        } else {
          setTechnologyNiche(data.data);
        }
      }

    } catch (error) {
      toastNotification("error", error.message);
      setIsLoading(false);
    }
  }

  const deleteTag = async (isRequiredTech, tagId) => {
    try {
      const confirmed = await showConfirmDialog(
        "Confirm!",
        isRequiredTech ? "Are you sure you want to delete technology?" : "Are you sure you want to delete tech niche?"
      );
      if (confirmed) {
        let url;
        if (isRequiredTech) {
          url = "/common/delete-required-technology"
        } else {
          url = "/common/delete-tech-niche"
        }

        const deleteTagApi = await postReq(url, {_id: tagId});
        if (deleteTagApi.status == 1) {
          toastNotification("success", deleteTagApi.message);
          fetchTags(isRequiredTech);
        } else {
          toastNotification("error", deleteTagApi.message);
          fetchTags(isRequiredTech);
        }
      }
    } catch (error) {
      toastNotification("error", "Unable to delete the tags");
      fetchTags(isRequiredTech);
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    fetchTags(true);
    fetchTags(false);
    setIsLoading(false);
  }, [])

  const columns = [
    {
      dataField: '_id',
      text: 'Id',
      sort: true,
      headerStyle: { width: "80px" },
      style: { width: "80px" },
      formatter: (cellCotent, row, rowIndex) => {
        return (
          <div>{rowIndex + 1}</div>
        )
      }
    },
    {
      dataField: 'label',
      text: 'Tech Niche Name',
      sort: true,
    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: (cellContent, row) => {
        return (
          <div>
            <button onClick={() => updateNewTechNiche(row)} className="btn btn-info btn-sm ml-2">
              Update
            </button>
            <button onClick={() => deleteTag(false, row._id)} className="btn btn-danger btn-sm ml-2">
              Delete
            </button>
          </div>
        );
      },
    }
  ];
  const columns2 = [
    {
      dataField: '_id',
      text: 'Id',
      sort: true,
      headerStyle: { width: "80px" },
      style: { width: "80px" },
      formatter: (cellCotent, row, rowIndex) => {
        return (
          <div>{rowIndex + 1}</div>
        )
      }
    },
    {
      dataField: 'label',
      text: 'Required Tech Name',
      sort: true,
    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: (cellContent, row) => {
        return (
          <div>
            <button onClick={() => updateNewRequiredTech(row)} className="btn btn-info btn-sm ml-2">
              Update
            </button>
            <button onClick={() => deleteTag(true, row._id)} className="btn btn-danger btn-sm ml-2">
              Delete
            </button>
          </div>
        );
      },
    }
  ];

   // update pagination options
  const updatepaginationOptions = (options) => {
    let tempFilters = { ...filters };
    tempFilters.options.page = options.page;
    tempFilters.options.limit = options.sizePerPage;
    setFilters(tempFilters);
  };

  const updateTechpaginationOptions = (options) => {
    let tempFilters = { ...techFilters };
    tempFilters.options.page = options.page;
    tempFilters.options.limit = options.sizePerPage;
    setTechFilters(tempFilters);
  };
  
  return (
    <div className="row">
      <div className="col-md-12 col-lg-12 text-right">
        <button
          type="button"
          onClick={() => addNewTechNiche()}
          className={`btn btn-primary font-weight-bold mr-2`}
        >
          <FaPlus /> Technology Niche
        </button>
        <button
          type="button"
          onClick={() => addNewRequiredTech()}
          className={`btn btn-primary font-weight-bold mr-2`}
        >
          <FaPlus /> Required Skills
        </button>
      </div>

      {showModalRequiredTech ? (
        <>
          <AddTags isRequiredTech={true} fetchTags={fetchTags} show={showModalRequiredTech} handleClose={closeRequiredTech} />
        </>
      ) : null}

      {showModalTechNiche ? (
        <>
          <AddTags isRequiredTech={false} fetchTags={fetchTags} show={showModalTechNiche} handleClose={closeTechNiche} />
        </>
      ) : null}

      {showUpdateRequiredTechModal ? (
        <>
          <UpdateTags isRequiredTech={true} show={showUpdateRequiredTechModal} handleClose={closeUpdateRequiredTech} fetchTags={fetchTags} data={requiredTechData} />
        </>
      ) : null}

      {showUpdateTechNicheModal ? (
        <>
        <UpdateTags isRequiredTech={false} show={showUpdateTechNicheModal} handleClose={closeUpdateTechNiche} fetchTags={fetchTags} data={techNicheData} />
        </>
      ) : null}
      {technologyNiche !== "" || requiredTechnology !== "" ? <div className="row mt-3">
        {technologyNiche !== "" && <div className="col-md-6 col-lg-6 col-xs-12">
          <Card>
            <CardBody>
              <ReusableTable columns={columns} data={technologyNiche?.tags} totalSize={technologyNiche?.totalCount}  updatepaginationOptions={updatepaginationOptions} keyField="id" isLoading={isLoading} />
            </CardBody>
          </Card>
        </div>}
        {requiredTechnology !== "" && <div className="col-md-6 col-lg-6 col-xs-12">
          <Card>
            <CardBody>
              <ReusableTable columns={columns2} data={requiredTechnology?.tags} totalSize={requiredTechnology?.totalCount} updatepaginationOptions={updateTechpaginationOptions} keyField="id2" isLoading={isLoading} />
            </CardBody>
          </Card>
        </div>}
      </div> : <div className="col-md-12 col-lg-12 col-xs-12"><Loading /></div>}
    </div>
  );
};

export default TechnologyNiche;
