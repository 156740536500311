import React, { useEffect, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
// import DatePicker from "react-datepicker";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { FaPlus } from "react-icons/fa";
import { postReq } from "../../../api";
import toastNotification from "../../../../helperFunc/toast";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import ReusableTable from "../../../components/ReusableTable";
import TimesheetViewModal from "./Views";
import { showConfirmDialog } from "../../../../helperFunc/helper";

const Timesheet = () => {
  const history = useHistory();
  const [selectedDate, setSelectedDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 15,
    search: ""
  });
  const [viewModalShow, setViewModalShow] = useState(false);
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);

  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const handleDateChange = (date) => {
    let tempFilters = { ...filters };
    tempFilters.search = moment(date).toDate();
    setSelectedDate(date);
    setOpen(false);
    setTimeout(() => {
      fetchData(tempFilters);
    }, 500);
  };

  // update pagination options
  const updatepaginationOptions = (options) => {
    let tempFilters = { ...filters };
    tempFilters.page = options.page;
    tempFilters.limit = options.sizePerPage;
    setFilters(tempFilters);
    fetchData(tempFilters);
  };

  const fetchData = async (filters) => {
    setIsLoading(true);
    try {
      const timesheetData = await postReq("/company/timesheets", filters);
      if (timesheetData.status == 1) {
        if (timesheetData.data.length == 0) {
          setData([]);
          setTotalCount(0);
          setIsLoading(false);
        } else {
          let timesheets = timesheetData.data.timesheet;
          let totalCount = timesheetData.data.totalCount;
          setData(timesheets);
          setTotalCount(totalCount);
          setIsLoading(false);
        }
      } else {
        setData([]);
        setTotalCount(0);
        toastNotification("error", timesheetData.message);
      }
    } catch (error) {
      setData([]);
      setTotalCount(0);
      toastNotification("error", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(filters);
  }, [filters]);

  const handleView = (timesheet) => {
    setSelectedTimesheet(timesheet);
    setViewModalShow(true);
  };

  const handleEdit = (id) => {
    history.push(`/company/timesheet/edit/${id}`);
  };

  const handleDelete = async (timesheetId) => {
    const confirmed = await showConfirmDialog(
      "Confirm!",
      "Are you sure you want to delete timesheet?"
    );
    if (confirmed) {
      const response = await postReq(`/company/delete-timesheet`, { _id: timesheetId });
      if (response.status === 1) {
        toastNotification("success", response.message);
        fetchData(filters);
      } else {
        toastNotification("error", response.message);
      }
    }
  };

  const columns = [
    { dataField: "date", 
      text: "Date",
      formatter: (cell, row) => {
        return <div>{moment(row.date).format('DD/MM/YYYY')}</div>
      }
    },
    { dataField: "projectName", text: "Project Name" },
    { dataField: "totalHours", text: "Total Hours",  formatter: (cell, row) => (
      <p>{`${row.totalHours} Hour`}</p>
    ) },
    {
      dataField: "actions",
      text: "Actions",
      formatter: (cell, row) => (
        <div>
          <button
            className="btn btn-sm btn-info mr-2"
            onClick={() => handleView(row)}
          >
            View Tasks
          </button>
          <button
            className="btn btn-sm btn-primary mr-2"
            onClick={() => handleEdit(row.parentId)}
          >
            Edit
          </button>
          <button
            className="btn btn-sm btn-danger"
            onClick={() => handleDelete(row.parentId)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="timesheet">
      <div className="row">
        <div className="col-md-6 col-lg-6 col-xs-12">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                variant="inline"
                views={['month','year']}
                label="Select Month"
                value={selectedDate}
                onChange={handleDateChange}
                format="MM/yyyy"
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
          </MuiPickersUtilsProvider>
          {/* <DatePicker
            placeholderText="Select Month"
            className="form-control form-control-solid h-auto py-3 px-2"
            selected={selectedDate}
            onChange={(date) => handleDateChange(date)}
            renderMonthContent={renderMonthContent}
            showMonthYearPicker
            dateFormat="MM/yyyy"
          /> */}
        </div>
        <div className="col-md-6 col-lg-6 col-xs-12 mt-2 mb-5 text-right">
          <button
            type="button"
            onClick={() => history.push("/company/timesheet/add")}
            className={`btn btn-primary font-weight-bold mr-2`}
          >
            <FaPlus /> Add Timesheet
          </button>
        </div>
        <div className="col-md-12 col-lg-12 col-xs-12">
          <Card>
            <CardBody>
              <ReusableTable
                columns={columns}
                data={data}
                totalSize={totalCount}
                updatepaginationOptions={updatepaginationOptions}
                keyField="_id"
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </div>
      </div>
      {selectedTimesheet && (
        <TimesheetViewModal
          show={viewModalShow}
          onHide={() => setViewModalShow(false)}
          timesheet={selectedTimesheet}
        />
      )}
    </div>
  );
};

export default Timesheet;
