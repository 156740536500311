
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { postReq } from "../../../api";
import ReusableTable from '../../../components/ReusableTable';
import {
    Card,
    CardBody
} from "../../../../_metronic/_partials/controls";
import toastNotification from "../../../../helperFunc/toast";
import { sortCaret } from "../../../../_metronic/_helpers";
import { CompanyRoutesConfig } from "../../../companyRoutesConfig";
import { showConfirmDialog } from "../../../../helperFunc/helper";
import AddRoleModal from "../../../components/AddRoleModal";
import UpdateRoleModal from "../../../components/UpdateRoleModal";

const Roles = () => {
    const [showEditModal, setShowEditModal] = useState(false);
    const [editData, setEditData] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [filters, setFilters] = useState({
        page: 1,
        limit: 10,
    });

    // update pagination options
    const updatepaginationOptions = (options) => {
        let tempFilters = { ...filters };
        tempFilters.page = options.page;
        tempFilters.limit = options.sizePerPage;
        setFilters(tempFilters);
    };

    const fetchData = async () => {
        setIsLoading(true);
        const rolesData = await postReq("/company/get-roles-list", filters);
        if (rolesData.status == 1) {
            if (rolesData.data.length == 0) {
                setRoles([]);
                setTotalCount(0)
                setIsLoading(false);
            } else {
                let roles = rolesData.data.roles;
                let totalCount = rolesData.data.totalCount
                setRoles(roles);
                setTotalCount(totalCount)
                setIsLoading(false);
            }
        } else {
            setRoles([]);
            setTotalCount(0);
            setIsLoading(false);
            toastNotification('error', rolesData.message);
        }
    };

    // get roles
    useEffect(() => {
        fetchData();
    }, []);

    const addNewRole = () => {
        setShowModal(true);
    }

    const deleteRole = async (id) => {
        try {
            const confirmed = await showConfirmDialog(
                "Confirm!",
                "Are you sure you want to delete role?"
            );
            if (confirmed) {
                const deleteRoleApi = await postReq("/company/delete-role", {_id: id});
                if (deleteRoleApi.status == 1) {
                  toastNotification("success", deleteRoleApi.message);
                  fetchData();
                } else {
                  toastNotification("error", deleteRoleApi.message);
                  fetchData();
                }
            }
        } catch (error) {
            toastNotification("error", error.message);
        }
    };

    const updateRole = async (id) => {
        let role = getRecordById(id);
        setEditData(role);
        setShowEditModal(true);
    };

    // get match record object by id

    function getRecordById(id) {
        return roles.find(record => record._id === id);
    }

    const closeModel = () => {
        setShowModal(false);
        fetchData();
    }

    const closeUpdateModal = () => {
        setShowEditModal(false);
        fetchData();
    };

    const columns = [
        {
          dataField: "name",
          text: "Role",
          sort: true,
          show: true,
          sortCaret: sortCaret,
        },
        {
          dataField: "permissions",
          text: "Permissions",
          sort: true,
          sortCaret: sortCaret,
          formatter: (cellContent, row) => {
            return <p>{row.permissions.join(', ')}</p>;
          },
        },
        {
          dataField: "actions",
          text: "Actions",
          formatter: (cellContent, row) => {
            return (
                <div>
                    <button onClick={() => updateRole(row._id)} className="btn btn-info btn-sm ml-2">
                    Update
                    </button>
                    <button onClick={() => deleteRole(row._id)} className="btn btn-danger btn-sm ml-2">
                    Delete
                    </button>
                </div>
            )
          },
        },
      ];
    return (
        <>
            {showModal ? (
                <>
                <AddRoleModal show={showModal} companyRoutesConfig={CompanyRoutesConfig} handleClose={closeModel}/>
                </>
            ) : null}
            {showEditModal ? (
                <>
                <UpdateRoleModal show={showEditModal} companyRoutesConfig={CompanyRoutesConfig} editData={editData} handleClose={closeUpdateModal}/>
                </>
            ) : null}
            <div className="row mb-3">
                <div className="col-md-12 col-lg-12 text-right">
                    <button
                        type="button"
                        onClick={() => addNewRole()}
                        className={`btn btn-primary font-weight-bold mr-2`}
                        >
                        <FaPlus /> Role
                    </button>
                </div>
            </div>
            <Card>
                <CardBody>
                    <ReusableTable
                        columns={columns}
                        data={roles}
                        totalSize={totalCount}
                        updatepaginationOptions={updatepaginationOptions}
                        keyField="roles"
                        isLoading={isLoading}
                    />
                </CardBody>
            </Card>
        </>
    );
};

export default Roles;