import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import "./styles.css";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { IoMdDownload } from "react-icons/io";

const data = [
  {
    id: "#312",
    jobTitle: "Devops Specialist",
    JobType: "Remote",
    JobLocation: "Ahemdabad, GJ",
    amount: "56,000",
  },
  {
    id: "#313",
    jobTitle: "Devops Specialist",
    JobType: "Remote",
    JobLocation: "Ahemdabad, GJ",
    amount: "56,000",
  },
  {
    id: "#314",
    jobTitle: "Devops Specialist",
    JobType: "Remote",
    JobLocation: "Ahemdabad, GJ",
    amount: "56,000",
  },
  {
    id: "#315",
    jobTitle: "Devops Specialist",
    JobType: "Remote",
    JobLocation: "Ahemdabad, GJ",
    amount: "56,000",
  },
  {
    id: "#316",
    jobTitle: "Devops Specialist",
    JobType: "Remote",
    JobLocation: "Mumbai",
    amount: "56,000",
  },
  {
    id: "#317",
    jobTitle: "Full Stakes Developer",
    JobType: "Remote",
    JobLocation: "Bengaluru",
    amount: "56,000",
  },
  {
    id: "#318",
    jobTitle: "Nodejs",
    JobType: "Remote",
    JobLocation: "Bengaluru",
    amount: "56,000",
  },
  {
    id: "#319",
    jobTitle: "Nodejs Full Stack",
    JobType: "Remote",
    JobLocation: "Bengaluru",
    amount: "80,000",
  },
  {
    id: "#319",
    jobTitle: "Aws Develoer",
    JobType: "Remote",
    JobLocation: "Bengaluru",
    amount: "40,000",
  }
];

const Invoice = () => {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <div className="invoices">
      <div className="row">
        {data.map((invoice, i) => {
          return (
            <div className="col-md-4 col-lg-4 col-xs-12 mb-5" key={i}>
            <div
              className="max-w-screen-sm mx-auto rounded-lg invoice-background"
              style={{ backgroundColr: "#388dd6" }}
            >
              <div className="bg-invoice p-5 rounded-lg">
                <h2 className="text-xl tracking-widest uppercase text-white">
                  Invoice
                  <span className="ml-2 font-mono text-base text-white-400">
                    {invoice.id}
                  </span>
                  <span
                    className="block w-16 mt-4 bg-blue-500"
                    style={{ height: 2 }}
                  />
                </h2>
              </div>
              <div className="p-5">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <div>
                      <span>Job Title:</span>
                      <br />
                      <span className="fw-bolder job-type">
                        {invoice.jobTitle}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <div>
                      <span>Job Type:</span>
                      <br />
                      <span className="fw-bolder job-type">{invoice.JobType}</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <div>
                    <span>Job Location:</span>
                    <br />
                    <span className="fw-bolder job-type">
                      {invoice.JobLocation}
                    </span>
                  </div>
                  <div>
                    <span>Final Budget</span>
                    <br />
                    <span className="fw-bolder ml-2 d-flex align-items-center">
                      <FaIndianRupeeSign /> {invoice.amount}
                    </span>
                  </div>
                </div>
                <div className="mt-5 d-flex align-items-center justify-content-between">
                  <button className="btn btn-primary">
                    <a
                      href=""
                      target="blank"
                      style={{ textDecoration: "none", color: "white" }}
                      className=" mt-1 p-0"
                    >
                      <IoMdDownload /> View Invoice
                    </a>
                  </button>
                  <br />
                  <button className="btn btn-danger">
                    <a
                      onClick={() => setShowModal(true)}
                      style={{ textDecoration: "none", color: "white" }}
                      className=" mt-1 p-0"
                    >
                      Raise Concern
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
          );
        })}
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Raise Your Concern</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            id="kt_login_signin_form"
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp vendor-onboarding-page"
          >
            <div className="form-group fv-plugins-icon-container">
              <label>Add Your Concern</label>
              <textarea
                placeholder="Add Your Concern"
                type="text"
                className={`form-control form-control-solid h-auto py-3 px-4 name`}
                name="name"
              ></textarea>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Invoice;
