import React, { useState } from "react";
import { useFormik } from "formik";
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from "yup";
import post from "../api";
import toastNotification from "../../../../helperFunc/toast";

const initialValues = {
    signedNda: '',
    signedMsa: ''
};

const UploadSignedDocSchema = Yup.object().shape({
    signedNda: Yup.string().required("Please upload your NDA document."),
    signedMsa: Yup.string().required("Please upload your MSA document."),
});

function UploadSignedDoc() {
  const { id } = useParams();
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: UploadSignedDocSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      const formData = new FormData();
      formData.append('approveId', id);
      formData.append('signedNda', values.signedNda);
      formData.append('signedMsa', values.signedMsa);
      try {
        const postData = await post('/vendor/upload-signed-documents', formData, {
            "Content-Type": "multipart/form-data",
        });
        if (postData.status == 1) {
            toastNotification('success', postData.message);
            disableLoading();
            setTimeout(() => {
              history.push("/auth/vendor/login")
            }, 2000);
          } else {
            disableLoading();
            toastNotification('error', postData.message);
          }
      } catch (error) {
        toastNotification('error', error.message);
        disableLoading();
      }
    },
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">Upload NDA/MSA Signed Documents</h3>
        <p className="text-muted font-weight-bold">
          Enter your NDA/MSA Signed Document to get registered for vendor
        </p>
      </div>
      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp vendor-onboarding-page"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}
        <div className="form-group fv-plugins-icon-container">
          <p style={{ marginBottom: "0.5rem" }}>Upload NDA Doc</p>
          <input
            placeholder="Upload NDA Doc"
            type="file"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "signedNda"
            )}`}
            name="signedNda"
            accept=".pdf"
            onChange={(e) =>
              formik.setFieldValue("signedNda", e.target.files[0])
            }
          />
          {formik.touched.signedNda && formik.errors.signedNda ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.signedNda}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <p style={{ marginBottom: "0.5rem" }}>Upload MSA Doc</p>
          <input
            placeholder="Upload MSA Doc"
            type="file"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "signedMsa"
            )}`}
            name="signedMsa"
            accept=".pdf"
            onChange={(e) =>
              formik.setFieldValue("signedMsa", e.target.files[0])
            }
          />
          {formik.touched.signedMsa && formik.errors.signedMsa ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.signedMsa}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={formik.isSubmitting || !(formik.dirty && formik.isValid)}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
    </div>
  );
}

export default UploadSignedDoc;