import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { postReq } from "../../../api";
import toastNotification from "../../../../helperFunc/toast";
import ReactMarkdown from "react-markdown";
import moment from "moment";
import "./styles.css";
import Loading from "../../../components/Loading";
import ApplyModal from "../../../components/applyModal";

const JobDetails = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState();
  const [jobDetails, setJobDetails] = useState();
  const [description, setDescription] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const splitDescription = (description) => {
    return description.split(". ");
  };

  const getJobDetails = async () => {
    try {
      setIsLoading(true);
      const getJob = await postReq("/vendor/requirement/get", {
        requirementId: id,
      });

      if (getJob.status == 1) {
        setJobDetails(getJob.data);
        console.log(getJob.data);
        setDescription(splitDescription(getJob.data.requirementJobDescription));
        setIsLoading(false);
      } else {
        setJobDetails({});
        setIsLoading(false);
        toastNotification("error", getJob.message);
      }
    } catch (error) {
      console.log("error ", error);
      toastNotification("error", "Something went wrong");
    }
  };

  useEffect(() => {
    getJobDetails();
  }, []);

  return (
    <>
      {show && (
        <ApplyModal
          show={show}
          requirementId={jobDetails?._id}
          handleClose={handleClose}
        />
      )}
      <div className="jobDetailsMainWrapper">
        {!isLoading ? (
          <div className="row">
            <div className="col-md-12 col-xs-12 col-lg-12">
              <div className="jobHeaderWrapper">
                <div className="jobHeaderSec1">
                  <p className="jobName">{jobDetails?.requirementName}</p>
                  <p className="companyName">{jobDetails?.companyId?.name}</p>
                  <div className="jobHeaderDetails">
                    <p className="jobHeaderItem">{jobDetails?.jobLocation}</p>
                    <p className="jobHeaderItem">
                      {moment(jobDetails?.createdAt).fromNow()}
                    </p>
                  </div>
                </div>
                <div className="jobHeaderSec2">
                  <div
                    className={
                      jobDetails?.requirementStatus == "open"
                        ? "applyBtn"
                        : "applyBtnDisabled"
                    }
                    onClick={() =>
                      jobDetails?.requirementStatus == "open"
                        ? setShow(true)
                        : setShow(false)
                    }
                  >
                    <p style={{ marginBlock: "0.4rem" }}>Apply</p>
                  </div>
                </div>
              </div>
              <div className="jobDetailsSection">
                <div className="row">
                  <div className="col-md-8 col-lg-8 col-xs-12">
                    <div className="jobDescription">
                      <p className="jobDescriptionHeading">Job Description</p>
                      <p className='jobDescriptionLine'><b>⇒</b>{description}</p>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 col-xs-12">
                    <div className="row">
                      <div className="col-md-12 col-lg-12 col-xs-12">
                        <div className="requiredSkills">
                          <p className="requiredSkillHeading">Skills</p>
                          <div className='row'>
                            {jobDetails?.requiredTechnology?.map((tag, index) => (
                              ((index < 10) ? (
                                <div className='col-md-6 col-lg-6 col-xs-12 my-2 text-center' key={index}>
                                  <p className='tagsJob'>{tag.value}</p>
                                </div>
                              ) : null)

                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-12 col-xs-12">
                        <div className="requiredSkills">
                          <p className="requiredSkillHeading">Budget</p>
                          <div className="row">
                            <div className="budgetWrapper">
                            <p className='budgetLine'>Budget for this role is <b>₹ {jobDetails?.budget}</b></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
};

export default JobDetails;
