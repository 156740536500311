import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import toastNotification from "../../../helperFunc/toast";
import { postReq } from "../../api";
import * as Yup from "yup";
import { useFormik } from "formik";

const AddTags = ({ isRequiredTech, show, handleClose, fetchTags }) => {
  const initialValues = {
    value: ""
  };

  const applySchema = Yup.object().shape({
    value: Yup.string()
      .min(3, "Value is too low")
      .max(20, "Value is too high")
      .required("Value is required")
  });

  const formik = useFormik({
    initialValues,
    validationSchema: applySchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      console.log("vlaues ", values);
      if (isRequiredTech) {
          await handleApiCall(values, true);
        } else {
            await handleApiCall(values, false)
        }
    },
  });
  const handleApiCall = async (data, isRequiredTech) => {
    try {
        let url;
        let fetch;
        if (isRequiredTech) {
            url = "/common/add-required-technology"
        } else {
            url = "/common/add-tech-niche"
        }
      const applyWithResume = await postReq(
        url,
        data
      );

      if (applyWithResume.status == 1) {
        toastNotification("success", applyWithResume.message);
        formik.resetForm();
        fetchTags(isRequiredTech)
        handleClose();
      } else {
        toastNotification("error", applyWithResume.message);
        formik.resetForm();
        handleClose();
      }
    } catch (error) {
      console.log("error in api ", error);
      toastNotification("error", error.message);
    }
  };
  return (
    <Modal
      show={show}
      className="tags-model"
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {isRequiredTech? "Create Required Technology Tags": "Create Techology Niche Tags"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Enter tag value</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter tag value"
              value={formik.values.value}
              onChange={(e) => formik.setFieldValue("value", e.target.value)}
            />
          </Form.Group>
          <div className="d-flex justify-content-end mt-4">
            <Button
              onClick={() => handleClose()}
              variant="danger"
              type="button"
            >
              Close
            </Button>
            <Button
              className="ml-2"
              disabled={!(formik.dirty && formik.isValid)}
              onClick={() => formik.handleSubmit()}
              variant="primary"
              type="button"
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddTags;
