import React from 'react';
import { useHistory } from "react-router-dom";
import { Card } from 'react-bootstrap';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import "./styles.css"
import moment from 'moment'
import { FaIndianRupeeSign } from 'react-icons/fa6';


const JobView = ({ _id, jobId, requirementName, requirementType, jobLocation, requirementStatus, requiredTechnology, minExperience, maxExperience, color, ...otherProps }) => {
    const history = useHistory();
    const { createdAt } = otherProps;
    let redirectionUrl;

    if (otherProps.isCompany != undefined || otherProps.isCompany != null) {
        if (otherProps.isCompany) {
            redirectionUrl = `/company/job-details/${_id}`
        } else {
            redirectionUrl = `/vendor/job-details/${_id}`
        }
    }

    let timeAgo = moment.utc(createdAt).local().startOf('seconds').fromNow();
    return (
        <>
            <div className='col-md-7 col-lg-7 col-xs-12'>
                <Card className='job-card' onClick={() => history.push(redirectionUrl)}>
                    <Card.Header style={{ background: color }}>
                        <div className='employ-dateil-hd'>
                            <h4 className='jobspecialization'>{requirementName}</h4>
                            <p className='com-tagline'>Dedicated Project</p>
                        </div>
                    </Card.Header>
                    <Card.Body>
                            <ul className="list-group">
                        <li className="list-group-item list-group-items">
                        <strong style={{'fontSize':"1.1rem"}}>Job ID : </strong>
                                    <span className='jobdetails'>{jobId}</span>
                        </li>
                        <li className="list-group-item list-group-items">
                        <strong  style={{'fontSize':"1.1rem"}}>Experience : </strong>
                                    <span className='jobdetails'>{`${minExperience} Year - ${maxExperience} Year`}</span>
                        </li>
                        <li className="list-group-item list-group-items">
                        <strong style={{'fontSize':"1.1rem"}}>Job Type : </strong>
                                    <span className='jobdetails'>{requirementType}</span>
                        </li>
                        <li className="list-group-item list-group-items">
                        <strong  style={{'fontSize':"1.1rem"}}>Location : </strong>
                                    <span className='jobdetails'>{jobLocation}</span>
                        </li>
                        <li className="list-group-item list-group-items d-flex removeBorder">
                                <strong  style={{'fontSize':"1.1rem"}}>Skills: </strong>
                                <div className='skilltagName'>
                                    {requiredTechnology.length > 0 && requiredTechnology.map((tech, i) => (
                                        <span className='skill-tag' key={`tech-${i}`}>{tech.value}</span>
                                    ))}
                                </div>
                            </li>
                      </ul>
                            
                        {/* </ul> */}
                    </Card.Body>
                </Card>
            </div>
            {/* <div className='box-wrapper' onClick={() => history.push(redirectionUrl)
            }>
                <div className='job-section1'>
                    <div className='jobIconWrapper'>
                        <img src={toAbsoluteUrl("/media/users/default.jpg")} className='jobIcon' />
                    </div>
                    <div className='jobDetailsWrapper'>
                        <p className='requirementName'>{requirementName}</p>
                        <div className='otherDetailsWrapper'>
                            <p className='otherDetails'>{timeAgo}</p>
                            <p className='otherDetails'>{requirementType}</p>
                            <p className='otherDetails'>{jobLocation}</p>
                        </div>
                    </div>
                </div>
                <div className='job-section2'>
                    {requiredTechnology.map((tag, index) => (
                        ((index < 10) ? (
                            <>
                                <div className='tagWrapper' key="index">
                                    <p className='tags'>{tag}</p>
                                </div>
                            </>
                        ) : null)

                    ))}
                </div>
            </div> */}
        </>
    )
}

export default JobView;