import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import toastNotification from "../../../helperFunc/toast";
import { postReq } from "../../api";
import * as Yup from "yup";
import { useFormik } from "formik";

const ApplyModal = ({ requirementId, show, handleClose }) => {
  const initialValues = {
    budget: "",
    resume: File,
    requirementId: requirementId,
  };

  const applySchema = Yup.object().shape({
    budget: Yup.string()
      .min(3, "Budget is too low")
      .max(6, "Budget is too high")
      .matches(/[0-9]/, "Budget should only be a number")
      .required("Budget is required"),
    resume: Yup.mixed()
      .required("Applicant's resume is required")
      .test("fileFormat", "Only PDF files are allowed", (value) => {
        if (value) {
          const supportedFormats = ["pdf"];
          return supportedFormats.includes(value.name.split(".").pop());
        }
        return true;
      })
      .test("fileSize", "File size must be less than 3MB", (value) => {
        if (value) {
          return value.size <= 3145728;
        }
        return true;
      }),
    requirementId: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: applySchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      console.log("vlaues ", values);
      await handleApiCall(values);
    },
  });
  const handleApiCall = async (data) => {
    try {
      const formData = new FormData();

      formData.append("budget", formik.values.budget);
      formData.append("resume", formik.values.resume);
      formData.append("requirementId", formik.values.requirementId);

      const applyWithResume = await postReq(
        "/vendor/requirement/apply",
        formData,
        { "Content-Type": "multipart/form-data" }
      );

      if (applyWithResume.status == 1) {
        toastNotification("success", applyWithResume.message);
        formik.resetForm();
        handleClose();
      } else {
        toastNotification("error", applyWithResume.message);
        formik.resetForm();
        handleClose();
      }
    } catch (error) {
      console.log("error in api ", error);
      toastNotification("error", error.message);
    }
  };
  return (
    <Modal
      show={show}
      className="apply-job-modal"
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Apply Requirement
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Upload your resource's resume</Form.Label>
            <Form.Control
              type="file"
              accept=".pdf"
              placeholder="Upload Resume"
              onChange={(e) =>
                formik.setFieldValue("resume", e.target.files[0])
              }
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Enter your budget </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter budget"
              value={formik.values.budget}
              onChange={(e) => formik.setFieldValue("budget", e.target.value)}
            />
          </Form.Group>
          <Button
            disabled={!(formik.dirty && formik.isValid)}
            onClick={() => formik.handleSubmit()}
            variant="primary"
            type="button"
          >
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ApplyModal;
