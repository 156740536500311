import React, { useEffect, useState } from "react";
import ReusableTable from "../../../components/ReusableTable";
import {
    Card,
    CardBody
  } from "../../../../_metronic/_partials/controls";
import { sortCaret } from "../../../../_metronic/_helpers";
import { IoDocumentText } from "react-icons/io5";
import { postReq } from "../../../api";
import toastNotification from "../../../../helperFunc/toast";
import { downloadZohoDoc } from "../../../../helperFunc/helper";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { FaExclamationCircle } from "react-icons/fa";


import axios from 'axios'

const ComplianceDocuments = () => {
  const sampleData = [
    {
      _id: 1,
      name: "Sedhal Soni",
      vendorEmail: "sedhal.soni@cloudstakes.com",
      msaDocumentUrl: "http://vendort2.com",
      ndaDocumentUrl: "http://vendort2.com",
      isNdaSigned: false,
      isMsaSigned: false,
    },
    {
      _id: 2,
      name: "Dilip Thakor",
      vendorEmail: "Dilip.thakor@cloudstakes.com",
      msaDocumentUrl: "http://vendort2.com",
      ndaDocumentUrl: "http://vendort2.com",
      isNdaSigned: false,
      isMsaSigned: false,
    },
    {
      _id: 3,
      name: "Dharam Joshi",
      vendorEmail: "dharam@cloudstakes.com",
      msaDocumentUrl: "http://vendort2.com",
      ndaDocumentUrl: "http://vendort2.com",
      isNdaSigned: false,
      isMsaSigned: false,
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState()
  const [filters, setFilters] = useState({
    page: 1,
    limit: 15,
  });
  // update pagination options
  const updatepaginationOptions = (options) => {
    let tempFilters = { ...filters };
    tempFilters.page = options.page;
    tempFilters.limit = options.sizePerPage;
    setFilters(tempFilters);
  };

  const handleApi = async (query)=> {
    let getDocs = await postReq('/company/get-compliance-doc', query);

    if (getDocs.status == 1) {
      if (getDocs.data.length == 0) {
        setData([]);
        setTotalCount(0)
      } else {
        let documents = getDocs.data.documents;
        let totalCount = getDocs.totalCount
        setData(documents);
        console.log('documets ', documents)
        setTotalCount(totalCount)
        // toastNotification("success", getDocs.message);
      }
    } else {
      toastNotification('error', getDocs.message);
      setData([]);
      setTotalCount(0)
    }
  }

  useEffect(()=> {
    setIsLoading(true);
    handleApi(filters);
    setIsLoading(false)
  }, []);

  const handleZohoDownload = async (documentId, requestId)=> {
     await axios(`${process.env.REACT_APP_API_URL}common/download-document`, {
        method: 'POST',
        data: {
          documentId: documentId,
          requestId: requestId
        }
         //Force to receive data in a Blob Format
    })
    .then(response => {
      console.log('response ', response.data)
    // //Create a Blob from the PDF Stream
    // //     const file = new Blob(
    // //       [response.data], 
    // //       {type: 'application/pdf'});
    // // //Build a URL from the file
    // //     const fileURL = URL.createObjectURL(file);
    // //Open the URL on new Window
    //     // window.open("data:application/pdf;base64, " + response.data);
    //     const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    //     console.log('pdf blob ', pdfBlob)
    //     // Convert Blob to URL
    //     const pdfUrl = URL.createObjectURL(pdfBlob);
    //     console.log('pdf url ', pdfUrl)
    //     // Set the PDF URL
    //     window.open(pdfUrl)
    })
    .catch(error => {
        console.log(error);
    });
  }

  const columns = [
    {
      dataField: "vendorDetails.name",
      text: "Name",
      sort: true,
      show: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "vendorDetails.vendorEmail",
      text: "Email",
      sort: true,
      headerStyle: { width: "275px" },
      style: { width: "275px" },
      sortCaret: sortCaret,
      formatter: (cellContent, row) => {
        console.log('row ', row)
        return <a href={`mailto:${row.vendorDetails.vendorEmail}`}>{row.vendorDetails.vendorEmail}</a>;
      },
    },
    {
      dataField: "msaDocumentUrl",
      text: "MSA",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          (row.zohoMsaDocumentId && row.zohoMsaRequestId && row.signedMsa && row.isMsaSigned) ? 
          <>
          <a href={process.env.REACT_APP_API + "files/" + `${row.signedMsa}`} target="_blank">
          <IoDocumentText /> View MSA
          </a>
        </>
            : <p>NA</p>
        );
      },
    },
    {
      dataField: "ndaDocumentUrl",
      text: "NDA",
      sort: true,
      formatter: (cellContent, row) => {
        console.log('row nda ', row)
        return (
          row.zohoNdaDocumentId && row.zohoNdaRequestId && row.signedNda && row.isNdaSigned ? 
            <>
              <a href={process.env.REACT_APP_API  + "files/" + `${row.signedNda}`} target="_blank">
              <IoDocumentText /> View NDA
              </a>
            </>
            : <p>NA</p>
        );
      },
    },
    {
      dataField: "isNdaSigned",
      text: "NDA Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => {
        if (row.isNdaSigned != undefined && row.isNdaSigned) {
          return <span className="badge badge-success"><RiVerifiedBadgeFill size={16} fill="white" style={{"paddingRight":"2px"}}/>Signed</span>;
        } else {
          return <span className="badge badge-danger"><FaExclamationCircle size={16} fill="white" style={{"paddingRight":"4px"}}/>Pending</span>;
        }
      },
    },
    {
      dataField: "isMsaSigned",
      text: "MSA Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => {
        console.log('row ', row)
        if (row.isMsaSigned != undefined && row.isMsaSigned) {
          return <span className="badge badge-success"><RiVerifiedBadgeFill size={16} fill="white" style={{"paddingRight":"2px"}}/>Signed</span>;
        } else {
          return <span className="badge badge-danger"><FaExclamationCircle size={16} fill="white" style={{"paddingRight":"4px"}}/>Pending</span>;
        }
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      formatter: (cellContent, row) => {
        return (
          (!row.isNdaSigned && !row.isMsaSigned) ? 
          <>
          <div>
            <button className="btn btn-success btn-sm ml-2">Resend</button>
          </div>
          </> : null
        )
      },
    },
  ];

  return (
    <Card>
        <CardBody>
            <ReusableTable
                columns={columns}
                data={data}
                totalSize={totalCount}
                updatepaginationOptions={updatepaginationOptions}
                keyField="compliance-doc"
                isLoading={isLoading}
            />
        </CardBody>
    </Card>
  );
};

export default ComplianceDocuments;
