import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import { CompanyRoutesConfig } from "./companyRoutesConfig";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import useUserDetails from "../helperFunc/useUserDetails";

export function Routes() {
  const { userRole, permissions } = useUserDetails();
  const companyToken = localStorage.getItem("companyToken");
  const vendorToken = localStorage.getItem("vendorToken");
  const userToken = localStorage.getItem("userToken");
  const isAuthorized = companyToken || vendorToken || userToken;
  const isCompany = userRole === "company";
  const isVendor = userRole === "vendor";

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : isCompany ? (
        <Redirect from="/auth/company/login" to="/company/dashboard" />
      ) : isVendor ? (
        <Redirect from="/auth/vendor/login" to="/vendor/dashboard" />
      ) : (
        <Redirect from="/auth/vendor/login" to="/company/dashboard" />
      )}
      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />
      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/vendor/login" />
      ) : (
        <Layout>
          <BasePage
            userRole={userRole}
            permissions={permissions}
            CompanyRoutesConfig={CompanyRoutesConfig}
          />
        </Layout>
      )}
    </Switch>
  );
}
