import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { postReq } from '../../../api';
import toastNotification from '../../../../helperFunc/toast';
import ReactMarkdown from 'react-markdown';
import moment from 'moment'
import "./styles.css"
import Loading from '../../../components/Loading';
import ApplyModal from '../../../components/applyModal';
import { useHistory } from 'react-router-dom'

const JobDetailsCompany = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState();
    const [jobDetails, setJobDetails] = useState();
    const [description, setDescription] = useState("");
    const [show, setShow] = useState(false);
    const history = useHistory();

    const handleClose = () => setShow(false);

    const splitDescription = (description) => {
        return description.split(". ")
    }

    const backToDeviceList = () => {
        history.push(`/company/requirements`);
    };

    const getJobDetails = async () => {
        try {
            setIsLoading(true)
            const getJob = await postReq("/company/requirement/get", { requirementId: id });

            if (getJob.status == 1) {
                setJobDetails(getJob.data);
                console.log(getJob.data);
                setDescription(getJob.data.requirementJobDescription);
                setIsLoading(false);
            } else {
                setJobDetails({});
                setIsLoading(false);
                toastNotification('error', getJob.message);
            }

        } catch (error) {
            console.log('error ', error);
            toastNotification('error', "Something went wrong")
        }
    }


    useEffect(() => {
        getJobDetails();
    }, []);

    console.log("jobDetails", jobDetails);

    return (
        <>
            {show ?
                <ApplyModal show={show} requirementId={jobDetails?._id} handleClose={handleClose} />
                : null}
            <div className='jobDetailsMainWrapper'>
                {!isLoading ? (
                    <>
                        <div className='backBtnWrapper'>
                            <button className='backBtn' onClick={() => backToDeviceList()}>{"Back"}</button>
                        </div>
                        <div className='row'>
                            <div className='col-md-12 col-xs-12 col-lg-12'>
                                <div className='jobHeaderWrapper'>
                                    <div className='jobHeaderSec1'>
                                        <div className='d-flex align-items-center'><p className='jobName'>{jobDetails?.requirementName}</p>
                                            <span className='experience'>({jobDetails?.minExperience} Year - {jobDetails?.maxExperience} Year)</span></div>
                                        <p className='companyName'>{jobDetails?.companyId?.name}</p>
                                        <div className='jobHeaderDetails'>
                                            <p className='jobHeaderItem'>{jobDetails?.jobLocation}</p>
                                            <p className='jobHeaderItem'>{moment(jobDetails?.createdAt).fromNow()}</p>
                                        </div>
                                    </div>
                                    <div className='jobHeaderSec2'>
                                        <div className="applyBtn" onClick={() => history.push(`/company/edit-job-details/${id}`)}>
                                            <p style={{ "marginBlock": "0.4rem" }}>Edit</p>
                                        </div>
                                        <div className="viewResponseBtn" onClick={() => history.push(`/company/view-responses/${jobDetails?._id}`)}>
                                            <p style={{ "marginBlock": "0.4rem" }}>View Responses</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='jobDetailsSection'>
                                    <div className='row'>
                                        <div className='col-md-8 col-lg-8 col-xs-12'>
                                            <div className='jobDescription'>
                                                <p className='jobDescriptionHeading'>Job Description</p>
                                                <p className='jobDescriptionLine'><b>⇒</b>{description}</p>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-lg-4 col-xs-12'>
                                            <div className='row'>
                                                <div className='col-md-12 col-lg-12 col-xs-12'>
                                                    <div className='requiredSkills'>
                                                        <p className='requiredSkillHeading'>Skills</p>
                                                        <div className='row'>
                                                            {jobDetails?.requiredTechnology?.map((tag, index) => (
                                                                ((index < 10) ? (
                                                                    <div className='col-md-6 col-lg-6 col-xs-12 my-2 text-center' key={index}>
                                                                        <p className='tagsJob'>{tag.value}</p>
                                                                    </div>
                                                                ) : null)

                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 col-lg-12 col-xs-12'>
                                                    <div className='requiredSkills'>
                                                        <p className='requiredSkillHeading'>Budget</p>
                                                        <div className='row'>
                                                            <div className='budgetWrapper'>
                                                                <p className='budgetLine'>Budget for this role is <b>₹ {jobDetails?.budget}</b></p>
                                                                <div className='budgetRange'>
                                                                    <p className='budgetINR'></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : <Loading />}

            </div>
        </>
    )
}

export default JobDetailsCompany;