import React, { useState, useEffect } from "react";
import paginationFactory, {
    PaginationProvider,
  } from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import PropTypes from "prop-types";
import { Pagination } from "../../../_metronic/_partials/controls";
import { NoRecordsFoundMessage } from "../../../_metronic/_helpers";
import Loading from "../../components/Loading";

const ReusableTable = ({ columns, data, totalSize, updatepaginationOptions, isLoading }) => {
  const [paginationOptions, setPaginationOptions] = useState({
    sizePerPage: 10,
    totalSize: totalSize,
    page: 1,
    custom: true,
  });

  useEffect(() => {
    const tempPaginationOptions = { ...paginationOptions };
    tempPaginationOptions.totalSize = totalSize;
    setPaginationOptions(tempPaginationOptions);
  }, [totalSize]);

  const handleTableChange = (type, { page, sizePerPage }) => {
    const tempPaginationOptions = { ...paginationOptions };
    tempPaginationOptions.page = page;
    tempPaginationOptions.sizePerPage = sizePerPage;
    setPaginationOptions(tempPaginationOptions);
    updatepaginationOptions(tempPaginationOptions);
  };
  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
                <BootstrapTable
                    wrapperClasses="table-responsive"
                    keyField="_id"
                    data={data}
                    columns={columns}
                    bordered={false}
                    remote
                    classes="table table-head-custom table-vertical-center overflow-hidden custom-table"
                    onTableChange={handleTableChange}
                    {...paginationTableProps}
                    noDataIndication={() => isLoading ? <Loading /> : <NoRecordsFoundMessage entities={data} />}
                />
            </Pagination>
          );
        }}
    </PaginationProvider>
  );
};

ReusableTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  keyField: PropTypes.string.isRequired,
};

export default ReusableTable;
