/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_helpers";
import useUserDetails from "../../../../../helperFunc/useUserDetails";

export function QuickUser() {
  const history = useHistory();
  const { userDetails, userRole } = useUserDetails();
  
  const logoutClick = () => {
      if (userRole == 'company') {
        localStorage.removeItem('companyDetails');
        localStorage.removeItem('companyToken');
        localStorage.removeItem('userRole');
        setTimeout(() => {
          window.location.reload();
        }, 100);
      } else if (userRole == 'vendor') {
        localStorage.removeItem('vendorDetails');
        localStorage.removeItem('vendorToken');
        localStorage.removeItem('userRole');
        setTimeout(() => {
          window.location.reload();
        }, 100);
      } else {
        localStorage.removeItem("userDetails");
        localStorage.removeItem('userToken');
        localStorage.removeItem('userRole');
        history.push('/auth/user/login');
        setTimeout(() => {
          window.location.reload();
        }, 100);
      } 
  };

  function getInitials(name) {
    if (name.includes(" ")) {
        // Split the name by space
        const nameParts = name.split(" ");
        // Get the first character of the first two parts
        const firstInitial = nameParts[0].charAt(0);
        const secondInitial = nameParts[1].charAt(0);
        return firstInitial + secondInitial;
    } else {
        // Get the first two characters of the single name part
        return name.charAt(0) + name.charAt(1);
    }
  }

  return (
      <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-7">
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
          <h3 className="font-weight-bold m-0">
            User Profile
          </h3>
          <a
              href="#"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              id="kt_quick_user_close"
          >
            <i className="ki ki-close icon-xs text-muted"/>
          </a>
        </div>

        <div
            className="offcanvas-content pr-5 mr-n5"
        >
          <div className="d-flex align-items-center mt-5">
            <div
                className="symbol symbol-100 mr-5"
            >
              <span className="symbol symbol-35 symbol-light-success">                
                <span className="symbol-label font-size-h5 font-weight-bold text-uppercase">{userDetails?.name && getInitials(userDetails?.name)}</span>
              </span>
              <i className="symbol-badge bg-success"/>
            </div>
            <div className="d-flex flex-column w-200">
              <a
                  href="#"
                  className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              >
                {userDetails?.name}
              </a>
              {/* <div className="text-muted mt-1">Application Developer</div> */}
              <div className="navi mt-2">
                <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                          src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Mail-notification.svg"
                          )}
                      ></SVG>
                    </span> 
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {userRole == "company" ? userDetails?.companyEmail : userRole == "vendor" ? userDetails?.vendorEmail: userDetails?.email}
                  </span>
                </span>
                </a>
              </div>
              <button className="btn btn-light-primary btn-bold" onClick={logoutClick}>Sign out</button>
            </div>
          </div>

          <div className="separator separator-dashed mt-8 mb-5"/>

          <div className="navi navi-spacer-x-0 p-0">
            <a href="/user/profile" className="navi-item">
              <div className="navi-link">
                <div className="navi-text">
                  <div className="font-weight-bold">My Profile</div>
                  <div className="text-muted">
                    Account settings and more
                    {" "}
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="separator separator-dashed my-1"></div>
          {userRole == 'company' ? 
          <div className="navi navi-spacer-x-0 p-0">
            <a href="/company/technology-niche" className="navi-item">
              <div className="navi-link">
                <div className="navi-text">
                  <div className="font-weight-bold">My Tags</div>
                  <div className="text-muted">
                    Required skills and Technology Niche
                    {" "}
                  </div>
                </div>
              </div>
            </a>
          </div>
          : null}
        </div>
      </div>
  );
}
