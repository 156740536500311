import Swal from "sweetalert2";
import axios from 'axios'

const getDataFromLocalStorageById = (id) => {
  // Retrieve data from localStorage
  const storedData = localStorage.getItem(id);
  // Check if data exists
  if (storedData) {
    // Parse the stored JSON data
    return JSON.parse(storedData);
  } else {
    // If data doesn't exist, return null or handle accordingly
    return null;
  }
};

const showConfirmDialog = async (
  title,
  text,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel"
) => {
  const result = await Swal.fire({
    title: title,
    text: text,
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: '#3699FF',
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
  });
  // Return a promise that resolves with the confirm result
  return result.isConfirmed;
};

const downloadZohoDoc = async (documentId, requestId) => {
  try {
    console.log('zoho ', process.env.REACT_APP_ZOHO_ACCESS_URL)
    let getAccessToken = await axios.post(process.env.REACT_APP_ZOHO_ACCESS_URL);
    let token;

    if (getAccessToken.status == 200 && getAccessToken.data !== undefined && getAccessToken.data !== null) {
      token =  getAccessToken.data?.access_token;

      let downloadDoc = await axios.get(`https://sign.zoho.com/api/v1/requests/${requestId}/documents/${documentId}/pdf`, {
        headers: {
          "Authorization":`Zoho oauth-token ${getAccessToken.data?.access_token}`
        }
      });

      if (downloadDoc.status == 200) {
        return 1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }

  } catch (error) {
    console.log('error ', error);
    return 0;
  }
}

export {
    getDataFromLocalStorageById,
    showConfirmDialog,
    downloadZohoDoc
}
