import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

// console.log('process ', process.env.REACT_API_URL)

const post = async (url ,data, headers) => {
    try {
        const apiCall = await axiosInstance.post(url, data, {
            headers: headers
        });

        if (apiCall.status == 200) {
            return apiCall.data;
        } else {
            console.log('api ', apiCall)
            return 0;
        }

    } catch (error) {
        console.log("error ", error.response)
        return error.response.data; 
    }
}

export default post;