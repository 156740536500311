import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { getReq, postReq } from "../../../api";
import ReusableTable from "../../../components/ReusableTable";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import toastNotification from "../../../../helperFunc/toast";
import { sortCaret } from "../../../../_metronic/_helpers";
import { showConfirmDialog } from "../../../../helperFunc/helper";
import AddUserModal from "../../../components/AddUserModal";
import UpdateUserModal from "../../../components/UpdateUserModal";

const Users = () => {
  let { _id } = JSON.parse(localStorage.getItem('companyDetails'));
  const [showEditModal, setShowEditModal] = useState(false);
  const [editData, setEditData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [users, setUserData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [roles, setRoles] = useState([]);
  const [skills, setSkills] = useState([]);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  });

  // update pagination options
  const updatepaginationOptions = (options) => {
    let tempFilters = { ...filters };
    tempFilters.page = options.page;
    tempFilters.limit = options.sizePerPage;
    setFilters(tempFilters);
  };

  const addUser = () => {
    setShowModal(true);
  };

  const fetchData = async () => {
    setIsLoading(true);
    const roles = await getReq("/company/roles");
    const skills = await postReq("/common/getTags", { type: "requiredTech", companyId: _id });
    const usersData = await postReq("/company/users", filters);
    if (usersData.status == 1) {
      if (usersData.data.length == 0) {
        setUserData([]);
        setTotalCount(0);
        setIsLoading(false);
      } else {
        let users = usersData.data.users;
        let totalCount = usersData.data.totalCount;
        setUserData(users);
        setTotalCount(totalCount);
        setIsLoading(false);
      }
    } else {
      setUserData([]);
      setTotalCount(0);
      setIsLoading(false);
      toastNotification("error", usersData.message);
    }
    setRoles(roles.data);
    setSkills(skills.data);
  };

  const deleteUser = async (id) => {
    try {
      const confirmed = await showConfirmDialog(
          "Confirm!",
          "Are you sure you want to delete user?"
      );
      if (confirmed) {
          const deleteUserApi = await postReq("/company/delete-user", {_id: id});
          if (deleteUserApi.status == 1) {
            toastNotification("success", deleteUserApi.message);
            fetchData();
          } else {
            toastNotification("error", deleteUserApi.message);
            fetchData();
          }
      }
  } catch (error) {
      toastNotification("error", error.message);
  }
  }

  const updateUser = async (id) => {
    const getUser = await postReq("/company/get-user", {_id: id});
    let data = JSON.parse(JSON.stringify(getUser.data));
    data.roleId = {
      _id: getUser.data.roleId._id,
      name: getUser.data.roleId.name
    }
    data.skills = [];
    getUser.data.skills && getUser.data.skills.length > 0 && getUser.data.skills.map((skill, i) => {
      data.skills.push({
        _id: skill._id,
        value: skill.value
      })
    });
    console.log("data--", data);
    setEditData(data);
    setShowEditModal(true);
  }

  // get match record object by id

  function getRecordById(id) {
    return users.find(record => record._id === id);
  }

  // get users
  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      show: true,
      headerStyle: { width: "150px" },
      style: { width: "150px" },
      sortCaret: sortCaret,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      show: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "mobileNo",
      text: "Mobile No",
      sort: true,
      show: true,
      headerStyle: { width: "150px" },
      style: { width: "150px" },
      sortCaret: sortCaret,
    },
    {
      dataField: "role",
      text: "Role",
      show: true,
      sortCaret: sortCaret,
      headerStyle: { width: "150px" },
      style: { width: "150px" },
      formatter: (cellContent, row) => {
        return <p className="mb-0">{row?.role?.name}</p>;
      },
    },
    {
      dataField: "skills",
      text: "skills",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => {
        return row.skills.map(skill => skill.value).join(', ');
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      formatter: (cellContent, row) => {
        return (
          <div>
            <button
              onClick={() => updateUser(row._id)}
              className="btn btn-info btn-sm ml-2"
            >
              Update
            </button>
            <button
              onClick={() => deleteUser(row._id)}
              className="btn btn-danger btn-sm ml-2"
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  const closeModel = () => {
    setShowModal(false);
    fetchData();
  }

  const closeUpdateModal = () => {
    setShowEditModal(false);
    fetchData();
  };

  return (
    <>
      {showModal ? (
        <>
        <AddUserModal show={showModal} roles={roles} skills={skills} handleClose={closeModel} />
        </>
      ) : null}
       {showEditModal ? (
          <>
          <UpdateUserModal show={showEditModal} roles={roles} skills={skills} editData={editData} handleClose={closeUpdateModal}/>
          </>
      ) : null}
      <div className="row mb-3">
        <div className="col-md-12 col-lg-12 text-right">
          <button
            type="button"
            onClick={() => addUser()}
            className={`btn btn-primary font-weight-bold mr-2`}
          >
            <FaPlus /> User
          </button>
        </div>
      </div>
      <Card>
        <CardBody>
          <ReusableTable
            columns={columns}
            data={users}
            totalSize={totalCount}
            updatepaginationOptions={updatepaginationOptions}
            keyField="users"
            isLoading={isLoading}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default Users;
