import { useState, useEffect } from 'react';
import { getDataFromLocalStorageById } from "./helper";

const useUserDetails = () => {
    const [userRole, setUserRole] = useState('');
    const [userDetails, setUserDetails] = useState('');
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        const userRoleByLocalStorage = localStorage.getItem('userRole');
        console.log('userRoleByLocalStorage ', userRoleByLocalStorage)
        const detailsSlug = userRoleByLocalStorage === 'company' ? 'companyDetails'
            : userRoleByLocalStorage === 'vendor' ? 'vendorDetails'
            : 'userDetails';
        console.log('details Slug ', detailsSlug)
        const userData = getDataFromLocalStorageById(detailsSlug);
        setUserRole(userRoleByLocalStorage);
        if (userData) {
            setUserDetails(userData);
            if (detailsSlug == 'userDetails' && userData?.roleId?.permissions) {
                setPermissions(userData.roleId.permissions);
            }
        }
    }, []);
    
    return { userRole, userDetails, permissions };
};

export default useUserDetails;