import React, { useState, useEffect } from "react";
import Select from "react-select";
import {Spinner, Button} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import toastNotification from "../../../helperFunc/toast";
import { postReq } from "../../api";
import * as Yup from "yup";
import { useFormik, Field } from "formik";

const AddUserModal = ({ show, roles, skills, handleClose }) => {
    const [skillArray, setSkillArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const initialValues = {
        name: "",
        email: "",
        mobileNo: "",
        roleId: "",
        skills: []
    };

    const applySchema = Yup.object().shape({
        name: Yup.string()
          .required("Name is required"),
        email: Yup.string()
          .email("Email is not valid")
          .required("Email is required"),
        mobileNo: Yup.string()
          .required("Mobile number is required")
          .matches(/^[0-9]{10}$/, "Mobile number is not valid"),
        roleId: Yup.string()
          .required("Role is required"),
        skills: Yup.array()
        .min(1, 'Select at least one skill')
        .required('Select at least one skill')
    });

    const formik = useFormik({
        initialValues,
        validationSchema: applySchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            try {
                setLoading(true);
                const addUser = await postReq("/company/create-user", values);
                if (addUser.status == 1) {
                    toastNotification("success", addUser.message);
                    setTimeout(() => {
                        formik.resetForm();
                        setLoading(false);
                        handleClose();
                    }, 2000);
                } else {
                    toastNotification("error", addUser.message);
                    setLoading(false);
                }
            } catch (error) {
                toastNotification("error", error.message);
                setLoading(false);
            }
        },
    });

    const handleChangeRole = (selectedValue) => {
        formik.setFieldValue("roleId", selectedValue._id)
    }

    const handleChangeSkills = (skillData) => {
        if (skillData !== null) {
          setSkillArray(skillData);
          let updatedSkills = [];
          if (skillData.length > 0) {
            skillData.map((skill) => {
                updatedSkills.push(skill._id);
            });
          }
          console.log('updated skills ', updatedSkills);
          formik.setFieldValue("skills", updatedSkills);
        } else {
          formik.setFieldValue("skills", []);
          setSkillArray([]);
        }
    };

    console.log("formik.errors", formik.errors, "--", formik.touched);
    return(
        <Modal
            show={show}
            className="apply-job-modal"
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add New User
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Name</Form.Label>
                        <input
                            placeholder="Enter Name"
                            type="text"
                            className={`form-control form-control-solid h-auto py-3 px-4`}
                            name="name"
                            {...formik.getFieldProps("name")}
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.name}
                            </div>
                            </div>
                        ) : null}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Email</Form.Label>
                        <input
                            placeholder="Enter Email"
                            type="email"
                            className={`form-control form-control-solid h-auto py-3 px-4`}
                            name="email"
                            {...formik.getFieldProps("email")}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.email}
                            </div>
                            </div>
                        ) : null}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Mobile No</Form.Label>
                        <input
                            placeholder="Enter Mobile No"
                            type="text"
                            className={`form-control form-control-solid h-auto py-3 px-4`}
                            name="mobileNo"
                            {...formik.getFieldProps("mobileNo")}
                        />
                        {formik.touched.mobileNo && formik.errors.mobileNo ? (
                            <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.mobileNo}
                            </div>
                            </div>
                        ) : null}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Role</Form.Label>
                        <Select
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option._id}
                            placeholder="Select Role"
                            options={roles}
                            onChange={(value) => handleChangeRole(value)}
                            name="roleId"
                            onBlur={(e) => formik.handleBlur(e)}
                        />
                        {formik.touched.roleId && formik.errors.roleId ? (
                            <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.roleId}
                            </div>
                            </div>
                        ) : null}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Skills</Form.Label>
                        <Select
                            getOptionLabel={option => option.value}
                            getOptionValue={option => option._id}
                            placeholder="Select Skills"
                            options={skills}
                            isMulti
                            onChange={(e) => handleChangeSkills(e)}
                            name="skills"
                            onBlur={(e) => formik.handleBlur(e)}
                            value={skillArray}
                        />
                        {formik.touched.skills && formik.errors.skills ? (
                            <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.skills}
                            </div>
                            </div>
                        ) : null}
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-4">
                        <Button
                            className={`btn btn-primary font-weight-bold ${loading ? 'px-9' : 'px-4'}`}
                            disabled={!(formik.dirty && formik.isValid)}
                            onClick={() => formik.handleSubmit()}
                            variant="primary"
                            type="button">
                            Submit
                            {loading && (
                                <span className="ml-2 spinner spinner-white"></span>
                            )}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddUserModal;
