import React, { useState, useEffect } from "react";
import { postReq } from "../../../api";
import "./styles.css";
import toastNotification from "../../../../helperFunc/toast";
import Loading from "../../../components/Loading";
import ApplicationView from "../../../components/ApplicationComponent";
import Select from "react-select";
import debounceSearchParams from "../../../../helperFunc/debounce";

const MyApplications = () => {
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
    cvScreeningStatus: "all",
  });

  const [searchStr, setSearchStr] = useState();

  const options = [
    {
      value: "all",
      label: "All",
    },
    {
      value: "shortlisted",
      label: "Shortlisted",
    },
    {
      value: "pending",
      label: "Pending",
    },
    {
      value: "rejected",
      label: "Rejected",
    },
    {
      value: "onhold",
      label: "On-Hold",
    },
  ];
  const [applicationData, setApplicationData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [selectedOption, setSelectedOption] = useState();

  const getApplied = async () => {
    try {
      setIsLoading(false);
      const getApplication = await postReq(
        "/vendor/requirement/apply-list",
        query
      );
      if (getApplication.status == 1) {
        if (
          getApplication.data &&
          getApplication?.data?.appliedRequirements?.length > 0
        ) {
          setApplicationData(getApplication.data.appliedRequirements);
        } else {
          setApplicationData([]);
        }
        setIsLoading(false);
      } else {
        toastNotification("error", getApplication.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error ", error);
      toastNotification("error", error.message);
    }
  };

  const handleQueryParams = (e) => {
    setSelectedOption(e);
    let value = e.value;
    setQuery((prev) => {
      const { searchParams, ...rest } = prev;
      return { ...rest, cvScreeningStatus: value };
    });
  };

  const handleSearchStr = (e) => {
    let string = e.target.value;
    setSearchStr(e.target.value);
    setQuery((prev) => {
      const { cvScreeningStatus, ...rest } = prev;
      return { ...rest, searchParams: { searchStr: string } };
    });
  };

  useEffect(() => {
    getApplied();
  }, []);
  useEffect(() => {
    getApplied();
  }, [query.cvScreeningStatus]);
  useEffect(() => {
    getApplied();
  }, [query]);

  useEffect(() => {
    console.log("we are hre ");
    const timeoutId = setTimeout(() => {
      debounceSearchParams(getApplied, 1000);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [query.searchParams]);
  return (
    <>
      <div>
        <div className="vendorDashboard">
          <div className="row">
            <div className="col-md-6 col-lg-6 col-xs-12">
              <div className="selectWrapper">
                <Select
                  className="searchSelect"
                  options={options}
                  defaultValue={options[0]}
                  value={selectedOption}
                  onChange={(e) => handleQueryParams(e)}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xs-12">
              <div className="searchWrapper">
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Search"
                    type="text"
                    className={`form-control form-control-solid`}
                    name="name"
                    style={{ backgroundColor: "white", width: "95%" }}
                    onChange={(e) => handleSearchStr(e)}
                    value={searchStr}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-12 col-xs-12">
              {!isLoading ? (
                applicationData.length > 0 ? (
                  applicationData.map((data, index) => (
                    <ApplicationView {...data} />
                  ))
                ) : (
                  <div> No Applications found!!</div>
                )
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyApplications;
