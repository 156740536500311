import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import toastNotification from "../../../helperFunc/toast";
import { postReq } from "../../api";
import * as Yup from 'yup'
import { useFormik } from 'formik'
import "./styles.css"
import Select from 'react-select'


const UpdateStatus = ({ requirementId, show, handleClose, currentStatus, isProfile, fetchData }) => {
    let initialValues = {
        appliedRequirementId: requirementId,
    };

    let profileStatusOptions = [
        {
            label: "In-Process",
            value: "inprocess"
        },
        {
            label: "Selected",
            value: "selected"
        },
        {
            label: "Rejected",
            value: "rejected"
        },
        {
            label: "On-Hold",
            value: "onhold"
        },
    ]
    let cvScreeningStatusOptions = [
        {
            label: "Pending",
            value: "pending"
        },
        {
            label: "Shortlisted",
            value: "shortlisted"
        },
        {
            label: "Rejected",
            value: "rejected"
        },
        {
            label: "On-Hold",
            value: "onhold"
        },
    ]
    let [profileStatus, setProfileStatus] = useState("");
    let [cvScreeningStatus, setCvScreeningStatus] = useState("");

    if (isProfile) {
        initialValues.profileStatus = "";
        initialValues.finalBudget = "";
        initialValues.sowDoc = File
    } else {
        initialValues.cvStatus = Boolean;
        initialValues.cvRejectionRemark = ""
    }

    const cvScreeningStatusSchema = Yup.object().shape({
        cvStatus: Yup.string().required("Status is required"),
        cvRejectionRemark: Yup.string().when("cvStatus", {
            is: "rejected",
            then: (schema) => schema.required("Rejection remark is required"),
        }),
        appliedRequirementId: Yup.string().required()
    });

    const profileScreeingStatusSchema = Yup.object().shape({
        profileStatus: Yup.string().required("Status is required"),
        sowDoc: Yup.mixed().when("profileStatus", {
            is: "selected",
            then: (schema) =>
                schema.required("Sow Doc is required")
                    .test('fileFormat', 'Only PDF files are allowed', value => {
                        if (value) {
                            const supportedFormats = ['pdf'];
                            return supportedFormats.includes(value.name.split('.').pop());
                        }
                        return true;
                    })
                    .test('fileSize', 'File size must be less than 3MB', value => {
                        if (value) {
                            return value.size <= 3145728;
                        }
                        return true;
                    })
        }),

        finalBudget: Yup.string().min(3, "Budget is too low")
            .max(6, "Budget is too high")
            .matches(/[0-9]/, "Budget should only be a number"),

        appliedRequirementId: Yup.string().required("Requirement Id is required")
    })

    const formik = useFormik({
        initialValues,
        // validationSchema: isProfile ? cvScreeningStatusSchema : profileScreeingStatusSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            console.log('vlaues ', values);
            await handleApiCall(values, isProfile)
        },
    });
    const handleApiCall = async (data, isProfile) => {
        try {

            console.log('data ', data)
            let formData;
            let url;
            let headers;

            if (isProfile) {
                url = "/company/requirement/application/update-profile-status";
                headers = { "Content-Type": "multipart/form-data" }
                formData = new FormData();
                console.log('applied requiremetn id ', data.appliedRequirementId)
                formData.append("appliedRequirementId", data.appliedRequirementId)
                formData.append("profileStatus", data.profileStatus)
                if (data.profileStatus == "selected") {
                    formData.append("sowDoc", data.sowDoc);
                }
                if (data.finalBudget && (data.finalBudget != null || data.finalBudget != undefined)) {
                    formData.append("finalBudget", data.finalBudget);
                }
            } else {
                url = "/company/requirement/application/update-cv-status"
                headers = {}
                formData = {
                    appliedRequirementId: data.appliedRequirementId,
                    cvStatus: data.cvStatus,
                }

                if (data.cvStatus == "rejected") {
                    formData.cvRejectionRemark = data.cvRejectionRemark
                }
            }

            const updateStatus = await postReq(url, formData, headers);

            if (updateStatus.status == 1) {
                toastNotification('success', updateStatus.message);
                formik.resetForm();
                fetchData();
                handleClose();
            } else {
                toastNotification("error", updateStatus.message);
                formik.resetForm();
                handleClose();
            }

        } catch (error) {
            console.log('error in api ', error);
            toastNotification('error', error.message)
        }
    }

    const setCurrentStateOnType = (type, isProfile) => {
        if (isProfile) {
            console.log('tyupe in useEffect ', type)
            console.log('isProfile in useEffect ', isProfile)
            switch (type) {
                case "inprocess":
                    setProfileStatus({
                        value: "inprocess",
                        label: "In-Process"
                    });
                    break;
                case "selected":
                    setProfileStatus({
                        value: "selected",
                        label: "Selected"
                    });
                    break;
                case "rejected":
                    setProfileStatus({
                        value: "rejected",
                        label: "Rejected"
                    });
                    break;
                default:
                    setProfileStatus({
                        value: "onhold",
                        label: "On-Hold"
                    });
                    break;
            }
        } else {
            switch (type) {
                case "pending":
                    setCvScreeningStatus({
                        value: "pending",
                        label: "Pending"
                    });
                    break;
                case "shortlisted":
                    setCvScreeningStatus({
                        value: "shortlisted",
                        label: "Shortlisted"
                    });
                    break;
                case "rejected":
                    setCvScreeningStatus({
                        value: "rejected",
                        label: "Rejected"
                    });
                    break;
                default:
                    setCvScreeningStatus({
                        value: "onhold",
                        label: "On-Hold"
                    });
                    break;
            }
        }

    }

    let handleStatusSelection = (selectedValue, isProfile) => {
        if (isProfile) {
            setProfileStatus(selectedValue);
            console.log('selcted value ', selectedValue.value)
            formik.setFieldValue("profileStatus", selectedValue.value)
        } else {
            setCvScreeningStatus(selectedValue);
            formik.setFieldValue('cvStatus', selectedValue.value)
        }
    }
    useEffect(() => {
        if (isProfile) {
            setCurrentStateOnType(currentStatus, true)
        } else {
            setCurrentStateOnType(currentStatus, false)
        }
    }, []);

    return (
        <>
            (
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {isProfile ? "Update Profile Status" : "Update Cv Screening Status"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isProfile ? (
                        <>
                            <div className="profileStatusFormWrapper">
                            <div className="form-group fv-plugins-icon-container">
                                <Select name="profileStatus" options={profileStatusOptions} value={profileStatus} onChange={(value) => handleStatusSelection(value, true)} />
                            </div>

                                {formik.values.profileStatus == "selected" ? (
                                    <>
                                        <div className="form-group fv-plugins-icon-container">
                                            <input className="form-control form-control-solid h-auto py-5 px-6" name="sowDoc" type="file" accept=".pdf" onChange={(e)=> formik.setFieldValue('sowDoc', e.target.files[0])}/>
                                        </div>
                                        <div className="form-group fv-plugins-icon-container">
                                            <input className="form-control form-control-solid h-auto py-5 px-6" placeholder="Enter your finalalize budget" name="finalBudget" type="text" onChange={(e)=> formik.setFieldValue('finalBudget', e.target.value)}/>
                                        </div>
                                    </>
                                ) :
                                    null}

                                <div className="modalButtonWrapperCustom">
                                    <Button variant="primary" onClick={()=> formik.handleSubmit()}>Save</Button>
                                    <Button variant="danger" className="mx-4" onClick={()=> handleClose()}>Close</Button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="cvScreeningStatusFormWrapper">
                            <div className="form-group fv-plugins-icon-container">
                                <Select name="cvScreeningStatus" options={cvScreeningStatusOptions} value={cvScreeningStatus} onChange={(value) => handleStatusSelection(value, false)} />
                            </div>

                                {formik.values.cvStatus == "rejected" ? (
                                    <>
                                        <div className="form-group fv-plugins-icon-container">
                                            <input className="form-control form-control-solid h-auto py-5 px-6" placeholder="Enter your rejection remark" name="cvRejectionRemark" type="text" onChange={(e)=> formik.setFieldValue('cvRejectionRemark', e.target.value)}/>
                                        </div>
                                    </>
                                ) :
                                    null}

                                <div className="modalButtonWrapperCustom">
                                    <Button variant="primary" onClick={()=> formik.handleSubmit()}>Save</Button>
                                    <Button variant="danger" className="mx-4" onClick={()=> handleClose()}>Close</Button>
                                </div>
                            </div>
                        </>
                    )}
                </Modal.Body>
            </Modal>
            );
        </>
    )
}

export default UpdateStatus;