import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;
let userRole = localStorage.getItem('userRole');
let authToken;
if (userRole == 'company') {
    authToken = localStorage.getItem("companyToken");
} else {
    authToken = localStorage.getItem("vendorToken")
}


let api = axios.create({
    baseURL: BASE_URL,
});

if (authToken) {
    api.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
}

export const getReq = async (endpoint, query) => {
    try {
        if (api.defaults.headers.common['Authorization'] == undefined || api.defaults.headers.common["Authorization"] == null) {
            let userRole = localStorage.getItem('userRole');
            let token;
            if (userRole == 'company') {
                token = localStorage.getItem("companyToken")
            } else {
                token = localStorage.getItem('vendorToken')
            }
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
        const response = await api.get(endpoint, query);
        return response.data;
    } catch (error) {
        // Handle error or throw an error
        console.log("error ", error.response)
        return error.response.data;
    }
};

export const postReq = async (endpoint, data) => {
    try {
        if (api.defaults.headers.common['Authorization'] == undefined || api.defaults.headers.common["Authorization"] == null) {
            let userRole = localStorage.getItem('userRole');
            let token = "";
            if (userRole == 'company') {
                token = localStorage.getItem("companyToken");
            } else if (userRole == 'vendor') {
                token = localStorage.getItem('vendorToken');
            } else {
                token = localStorage.getItem("userToken");
            }
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
        const response = await api.post(endpoint, data);
        return response.data;
    } catch (error) {
        // Handle error or throw an error
        console.log("error ", error.response)
        return error.response.data;
    }
};

export const putReq = async (endpoint, data) => {
    if (api.defaults.headers.common['Authorization'] == undefined || api.defaults.headers.common["Authorization"] == null) {
        let userRole = localStorage.getItem('userRole');
        let token;
        if (userRole == 'company') {
            token = localStorage.getItem("companyToken")
        } else {
            token = localStorage.getItem('vendorToken')
        }
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    try {
        const response = await api.put(endpoint, data);
        return response.data;
    } catch (error) {
        // Handle error or throw an error
        console.log("error ", error.response)
        return error.response.data;
    }
};

export const deleteApi = async (endpoint) => {
    if (api.defaults.headers.common['Authorization'] == undefined || api.defaults.headers.common["Authorization"] == null) {
        let userRole = localStorage.getItem('userRole');
        let token;
        if (userRole == 'company') {
            token = localStorage.getItem("companyToken")
        } else {
            token = localStorage.getItem('vendorToken')
        }
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    try {
        const response = await api.delete(endpoint);
        return response;
    } catch (error) {
        // Handle error or throw an error
        console.log("error ", error.response)
        return error.response.data;
    }
};