import React, { useState, useEffect } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import "./styles.css"
import { useHistory } from "react-router-dom";
import moment from 'moment'
import RejectionModal from '../RejectionModal';


const ApplicationView = ({ _id, requirementDetails, ...otherProps }) => {
    const history = useHistory();
    const { cvScreeningStatus, createdAt } = otherProps;
    let { requirementName, requirementType, jobLocation, requirementStatus } = requirementDetails;
    let [tagClassName, setTagClassName] = useState("");
    let [profileStatusClass, setProfileStatusClass] = useState("");
    let [profileStatus, setProfileStatus] = useState(null);
    let [cvRejectionRemark, setCvRejectionRemark] = useState(null);
    let [showRejectionModal, setShowRejectionModal] = useState(false);

    const handleCloseRejectionModal = ()=> {
        setShowRejectionModal(false);
    }

    const handleProfileStatus = (type) => {
        switch (type) {
            case "selected":
                setProfileStatusClass("profileWrapperSelected")
                break;
            case "inprocess":
                setProfileStatusClass("profileWrapperInProcess")
                break;
            case "rejected":
                setProfileStatusClass("profileWrapperRejected")
                break;
            case "onhold":
                setProfileStatusClass("profileWrapperOnHold")
                break;
            default:
                break;
        }
    }

    const handleCvScreeningStatus = (type) => {
        switch (type) {
            case "shortlisted":
                setProfileStatus(otherProps.profileStatus);
                setTagClassName("tagWrapperShortlisted");
                handleProfileStatus(otherProps.profileStatus);
                setCvRejectionRemark(null);
                break;
            case "rejected":
                setCvRejectionRemark(otherProps.cvRejectionRemark);
                setTagClassName("tagWrapperRejected");
                setProfileStatus(null)
                break;
            case "onhold":
                setTagClassName("tagWrapperOnHold");
                setCvRejectionRemark(null);
                setProfileStatus(null);
                break;
            default:
                setTagClassName("tagWrapperPending");
                setCvRejectionRemark(null);
                setProfileStatus(null);
                break;
        }
    }

    useEffect(() => {
        handleCvScreeningStatus(cvScreeningStatus)
    }, [cvScreeningStatus])

    let timeAgo = moment.utc(createdAt).local().startOf('seconds').fromNow();
    return (
        <>
            {showRejectionModal ? 
                <RejectionModal 
                    show={showRejectionModal} 
                    handleClose={handleCloseRejectionModal} 
                    rejectionReasonStr={cvRejectionRemark}/> 
                : null}
            <div className='box-wrapper'>
                <div className='job-section1'>
                    <div className='jobIconWrapper'>
                        <img src={toAbsoluteUrl("/media/Icons/jobApplication.png")} className='jobIcon' />
                    </div>
                    <div className='jobDetailsWrapper'>
                        <p className='requirementName'>{requirementName}</p>
                        <div className='otherDetailsWrapper'>
                            <p className='otherDetails'>{timeAgo}</p>
                            <p className='otherDetails'>{requirementType}</p>
                            <p className='otherDetails'>{jobLocation}</p>
                        </div>
                    </div>
                </div>
                <div className='job-section2'>
                    <div className='jobSecHeader'>
                        <p className='statusHeader'>Cv Screening</p>
                        <div className={tagClassName}>
                            <p className='tags'>{cvScreeningStatus.toUpperCase()}</p>
                        </div>
                    </div>

                    {profileStatus != null ? (
                        <div className='jobSecHeader'>
                            <p className='statusHeader'>Profile</p>
                            <div className={profileStatusClass}>
                                <p className='tags'>{profileStatus.toUpperCase()}</p>
                            </div>
                        </div>
                    ) : null}
                    {cvRejectionRemark != null ? (
                        <div className='jobSecHeader'>
                            <p className='statusHeader'>Rejection Remark</p>
                            <p className='rejectionReason' onClick={()=> setShowRejectionModal(true)}>View Reason</p>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    )
}

export default ApplicationView;