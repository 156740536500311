import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody } from "../../../../../_metronic/_partials/controls";
import DateFnsUtils from "@date-io/date-fns";
import { TextField, FormControl, IconButton } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { postReq } from "../../../../api";
import toastNotification from "../../../../../helperFunc/toast";

const validationSchema = Yup.object().shape({
  date: Yup.date().required("Date is required"),
  projectName: Yup.string().required("Project Name is required"),
  tasks: Yup.array().of(
      Yup.object().shape({
        taskName: Yup.string().required("Task Name is required"),
        taskDesc: Yup.string().required("Task Desc is required"),
        startTime: Yup.date().required("Start Time is required"),
        endTime: Yup.date()
          .required("End Time is required")
          .min(Yup.ref("startTime"), "End Time must be later than Start Time")
          .test(
            "is-after-15-min",
            "End Time must be at least 15 minutes after Start Time",
            function(endTime) {
              const { startTime } = this.parent;
              const fifteenMinutesAfterStart = new Date(startTime);
              fifteenMinutesAfterStart.setMinutes(
                fifteenMinutesAfterStart.getMinutes() + 15
              );
              return endTime >= fifteenMinutesAfterStart;
            }
          ),
      })
    )
    .test("no-overlapping-tasks", "Tasks should not overlap", function (tasks) {
      if (tasks && tasks.length > 1) {
        const sortedTasks = tasks.slice().sort((a, b) => new Date(a.startTime) - new Date(b.startTime));

        for (let i = 0; i < sortedTasks.length - 1; i++) {
          const currentTask = sortedTasks[i];
          const nextTask = sortedTasks[i + 1];

          if (new Date(currentTask.endTime) > new Date(nextTask.startTime)) {
            return this.createError({
              path: `tasks[${i + 1}].startTime`,
              message: `Task ${i + 2} overlaps with Task ${i + 1}`
            });
          }
        }
      }
      return true;
    }),
});


const AddTimesheet = () => {
  const initialValues = {
    date: "",
    projectName: "",
    tasks: [
      {
        taskName: "",
        taskDesc: "",
        startTime: "",
        endTime: "",
      },
    ],
  };
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const backToPreviousPage = () => {
    history.push(`/company/timesheets`);
  };

  const handleSubmit = async (
    values,
    { resetForm, setSubmitting, setLoading }
  ) => {
    try {
      setLoading(true);
      const AddTimeSheet = await postReq("/company/create-timesheet", values);
      if (AddTimeSheet.status === 1) {
        toastNotification("success", AddTimeSheet.message);
        setTimeout(() => {
          resetForm();
          history.push(`/company/timesheets`);
        }, 2000);
      } else {
        toastNotification("error", AddTimeSheet.message);
        setLoading(false);
      }
    } catch (error) {
      toastNotification("error", error.message);
      setLoading(false);
    }
    setSubmitting(false);
  };

  return (
    <div className="add-timesheet">
      <div className="backBtnWrapper">
        <h4>Add New Task</h4>
        <button className="backBtn" onClick={() => backToPreviousPage()}>
          {"Back"}
        </button>
      </div>
      <div className="row">
        <div className="col-md-12 col-lg-12 col-xs-12">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, formikHelpers) =>
              handleSubmit(values, { ...formikHelpers, setLoading })
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => (
              <Form>
                <Card>
                  <CardBody>
                    <div className="row">
                      <div className="col-md-3 col-lg-3 col-xs-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            margin="normal"
                            className="mt-0"
                            id="date-picker-dialog"
                            label="Select Date"
                            format="MM/dd/yyyy"
                            value={values.date ? new Date(values.date) : null}
                            onChange={(date) => setFieldValue("date", date)}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            error={touched.date && Boolean(errors.date)}
                            helperText={touched.date && errors.date}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col-md-4 col-lg-4 col-xs-6">
                        <FormControl fullWidth variant="standard">
                          <Field
                            as={TextField}
                            margin="normal"
                            className="mt-0"
                            id="project-name"
                            name="projectName"
                            label="Project Name"
                            value={values.projectName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.projectName && Boolean(errors.projectName)
                            }
                            helperText={
                              touched.projectName && errors.projectName
                            }
                          />
                        </FormControl>
                      </div>
                    </div>
                    <h5 className="mt-4 mb-0">Add Tasks</h5>
                    <FieldArray name="tasks">
                      {({ push, remove }) => (
                        <div className="mb-4">
                          {values.tasks.map((task, index) => (
                            <>
                              <h6 className="mt-8 mb-2">Task {index + 1}</h6>
                              <div
                                className="row d-flex align-items-start"
                                key={index}
                              >
                                <div className="col-md-6 col-lg-6 col-xs-12 mb-3">
                                  <FormControl fullWidth variant="standard">
                                    <Field
                                      as={TextField}
                                      id={`task-name-${index}`}
                                      name={`tasks[${index}].taskName`}
                                      label="Task Name"
                                      value={task.taskName}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={
                                        touched.tasks?.[index]?.taskName &&
                                        Boolean(errors.tasks?.[index]?.taskName)
                                      }
                                      helperText={
                                        touched.tasks?.[index]?.taskName &&
                                        errors.tasks?.[index]?.taskName
                                      }
                                    />
                                  </FormControl>
                                </div>
                                <div className="col-md-2 col-lg-2 col-xs-6">
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                      id={`start-time-picker-${index}`}
                                      label="Start Time"
                                      value={
                                        task.startTime
                                          ? new Date(task.startTime)
                                          : null
                                      }
                                      onChange={(time) =>
                                        setFieldValue(
                                          `tasks[${index}].startTime`,
                                          time
                                        )
                                      }
                                      KeyboardButtonProps={{
                                        "aria-label": "change time",
                                      }}
                                      error={
                                        touched.tasks?.[index]?.startTime &&
                                        Boolean(
                                          errors.tasks?.[index]?.startTime
                                        )
                                      }
                                      helperText={
                                        touched.tasks?.[index]?.startTime &&
                                        errors.tasks?.[index]?.startTime
                                      }
                                    />
                                  </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-md-2 col-lg-2 col-xs-6">
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                      id={`end-time-picker-${index}`}
                                      label="End Time"
                                      value={
                                        task.endTime
                                          ? new Date(task.endTime)
                                          : null
                                      }
                                      onChange={(time) =>
                                        setFieldValue(
                                          `tasks[${index}].endTime`,
                                          time
                                        )
                                      }
                                      KeyboardButtonProps={{
                                        "aria-label": "change time",
                                      }}
                                      error={
                                        touched.tasks?.[index]?.endTime &&
                                        Boolean(errors.tasks?.[index]?.endTime)
                                      }
                                      helperText={
                                        touched.tasks?.[index]?.endTime &&
                                        errors.tasks?.[index]?.endTime
                                      }
                                    />
                                  </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-md-1 col-lg-1 col-xs-1 d-flex align-items-center">
                                  {index === values.tasks.length - 1 &&
                                    index < 4 && (
                                      <IconButton
                                        onClick={() =>
                                          push({
                                            taskName: "",
                                            taskDesc: "",
                                            startTime: "",
                                            endTime: "",
                                          })
                                        }
                                      >
                                        <IoIosAddCircleOutline />
                                      </IconButton>
                                    )}
                                  <IconButton
                                    onClick={() => remove(index)}
                                    disabled={values.tasks.length === 1}
                                  >
                                    <IoIosRemoveCircleOutline />
                                  </IconButton>
                                </div>
                                <div className="col-md-6 col-lg-6 col-xs-12">
                                  <FormControl fullWidth variant="standard">
                                    <Field
                                      as={TextField}
                                      id={`task-desc-${index}`}
                                      name={`tasks[${index}].taskDesc`}
                                      label="Task Desc"
                                      multiline
                                      rows={3}
                                      value={task.taskDesc}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={
                                        touched.tasks?.[index]?.taskDesc &&
                                        Boolean(errors.tasks?.[index]?.taskDesc)
                                      }
                                      helperText={
                                        touched.tasks?.[index]?.taskDesc &&
                                        errors.tasks?.[index]?.taskDesc
                                      }
                                    />
                                  </FormControl>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      )}
                    </FieldArray>
                  </CardBody>
                </Card>
                <div className="d-flex flex-wrap justify-content-end mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary font-weight-bold px-9 py-3"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddTimesheet;
