/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {Link, Switch, Redirect} from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {ContentRoute} from "../../../../_metronic/layout"
import Login from "./Login";
// import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import CompanyRegistration from "./companyRegistration";
import CompanyLogin from "./companyLogin";
import companyUserLogin from "./companyUserLogin";
import VendorLogin from "./VendorLogin";
import VendorOnboarding from "./VendorOnboarding";
import uploadSignedDoc from "./UploadSignedDoc";

export function AuthPage() {
  let [pathname, setPathname] = useState(window.location.pathname);
  let [isVendorRoutes, setIsVendorRoutes] = useState(false);
  let [isCompanyLogin, setIsCompanyLogin] = useState(false);
  let [isCompanyRegistration, setIsCompanyRegistration] = useState(false);
  

  useEffect(()=> {
    setPathname(window.location.pathname)
  }, [])

  useEffect(()=> {
    console.log("window.location.pathname", pathname);
    console.log("------", pathname.includes('/auth/company/login'));
    if (pathname.includes('/auth/company/registration')) {
      console.log('we are in 1')
      setIsCompanyRegistration(true);
      setIsVendorRoutes(false);
      setIsCompanyLogin(false)
    } else if (pathname.includes('/auth/company/login')) {
      console.log("we are in 2")
      setIsCompanyLogin(true);
      setIsCompanyRegistration(false);
      setIsVendorRoutes(false)
    } else if (pathname.includes('/auth/vendor/login')) {
      console.log('we are in 3')
      setIsVendorRoutes(true);
      setIsCompanyRegistration(false);
      setIsCompanyLogin(false)
    } else {
      console.log('we are in 4')
      setIsCompanyLogin(false);
      setIsCompanyRegistration(false);
      setIsVendorRoutes(false);
    }
  }, [pathname])
  return (
      <>
        <div className="d-flex flex-column flex-root">
          {/*begin::Login*/}
          <div
              className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
              id="kt_login"
          >
            {/*begin::Aside*/}
            <div
                className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,
                  overflow: "hidden"
                }}
            >
              {/*begin: Aside Container*/}
              <div className="d-flex flex-row-fluid flex-column justify-content-between" style={{
                    // "max": 100vh;
                    // height: 100vh;
                    // overflow-y: scroll !important;
              }}>
                {/* start:: Aside header */}
                <Link to="/" className="flex-column-auto mt-5">
                  <h1 className="text-white">RMT</h1>
                  {/* <img
                      alt="Logo"
                      className="max-h-70px"
                      src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
                  /> */}
                </Link>
                {/* end:: Aside header */}

                {/* start:: Aside content */}
                <div className="flex-column-fluid d-flex flex-column justify-content-center">
                  <h3 className="font-size-h1 mb-5 text-white">
                    Welcome to Resource Management Tool!
                  </h3>
                  <p className="font-weight-lighter text-white opacity-80">
                    Resource management tool, enables vendor companies and companies connect and provides resources
                  </p>
                </div>
                {/* end:: Aside content */}

                {/* start:: Aside footer for desktop */}
                <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                  <div className="opacity-70 font-weight-bold	text-white">
                    &copy; 2024 Cloudstakes
                  </div>
                  {/* <div className="d-flex">
                    <Link to="/terms" className="text-white">
                      Privacy
                    </Link>
                    <Link to="/terms" className="text-white ml-10">
                      Legal
                    </Link>
                    <Link to="/terms" className="text-white ml-10">
                      Contact
                    </Link>
                  </div> */}
                </div>
                {/* end:: Aside footer for desktop */}
              </div>
              {/*end: Aside Container*/}
            </div>
            {/*begin::Aside*/}

            {/*begin::Content*/}
            <div className="flex-row-fluid d-flex flex-column position-relative p-7" style={{
              "maxHeight":"100vh",
              "height":"100vh",
              "overflowY":"scroll"
            }}>
              {/*begin::Content header*/}

              {isVendorRoutes ? (
                <div className="text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-start py-5 px-10">
                <span className="font-weight-bold text-dark-50">Login As Company</span>
                <Link to="/auth/company/login" onClick={()=> setPathname("/auth/company/login")} className="font-weight-bold ml-2" id="kt_login_signup1">Company Login</Link>
              </div>
              ) : null }

              {isCompanyLogin ? (
                <div className="text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-start py-5 px-10">
                <span className="font-weight-bold text-dark-50">Login As Vendor</span>
                <Link to="/auth/vendor/login" onClick={()=> setPathname("/auth/vendor/login")} className="font-weight-bold ml-2" id="kt_login_signup1">Vendor Login</Link>
              </div>
              ) : null }

              {isCompanyRegistration ? ( <>
                 <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50">Login As Company</span>
              <Link to="/auth/company/login" onClick={()=> setPathname("/auth/company/login")} className="font-weight-bold ml-2" id="kt_login_signup">Company Login</Link>
            </div>
             <div className="text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-start py-5 px-10">
             <span className="font-weight-bold text-dark-50">Login As Vendor</span>
             <Link to="/auth/vendor/login" onClick={()=> setPathname("/auth/vendor/login")} className="font-weight-bold ml-2" id="kt_login_signup1">Vendor Login</Link>
           </div> </>) : null} 
              {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                <span className="font-weight-bold text-dark-50">Register As Company</span>
                <Link to="/auth/company/registration" className="font-weight-bold ml-2" id="kt_login_signup">Company Registration</Link>
              </div>
              <div className="text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-start py-5 px-10">
                <span className="font-weight-bold text-dark-50">Login As Vendor</span>
                <Link to="/auth/vendor/login" className="font-weight-bold ml-2" id="kt_login_signup1">Vendor Login</Link>
              </div>
              <div className="text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-start py-5 px-10">
                <span className="font-weight-bold text-dark-50">Login As Company</span>
                <Link to="/auth/company/login" className="font-weight-bold ml-2" id="kt_login_signup1">Company Login</Link>
              </div> */}
              {/*end::Content header*/}

              {/* begin::Content body */}
              <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                <Switch>
                {/* <ContentRoute path="/auth/login" component={Login}/> */}
                <ContentRoute path="/auth/company/registration" component={CompanyRegistration}/>
                <ContentRoute
                    path="/auth/forgot-password"
                    component={ForgotPassword}
                />
                <ContentRoute path="/auth/company/login" component={CompanyLogin}/>
                <ContentRoute path="/auth/user/login" component={companyUserLogin}/>
                <ContentRoute path="/auth/vendor/login" component={VendorLogin}/>
                <ContentRoute path="/auth/vendor-onboarding/:id" component={VendorOnboarding}/>
                <ContentRoute path="/auth/upload-signed-doc/:id" component={uploadSignedDoc}/>
                <Redirect from="/auth" exact={true} to="/auth/company/login"/>
                <Redirect to="/auth/company/login"/>
              </Switch>
              </div>
              {/*end::Content body*/}

              {/* begin::Mobile footer */}
              <div
                  className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                  &copy; 2024 Cloudstakes
                </div>
              </div>
              {/* end::Mobile footer */}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Login*/}
        </div>
      </>
  );
}
