import React from "react";
import {Spinner} from 'react-bootstrap'
import "./styles.css"

const Loading = ()=> {
    return (
        <>
            <div className="loadingWrapper">
                <Spinner animation="grow" variant="primary" size="lg" className="mx-2" />
                <Spinner animation="grow" variant="primary" size="lg" className="mx-2"/>
                <Spinner animation="grow" variant="primary" size="lg" className="mx-2"/>
                <Spinner animation="grow" variant="primary" size="lg" className="mx-2"/>
            </div>
        </>
    )
}

export default Loading;