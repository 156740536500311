import React, {useEffect, useCallback, useState} from "react";
import {withRouter} from 'react-router-dom';
import _ from 'lodash';
import { Button, Modal } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import JobView from "../../components/JobComponent";
import { postReq } from "../../api";
import toastNotification from "../../../helperFunc/toast";
import Loading from "../../components/Loading";
import AddRequirement from "./AddRequirement";


const CompanyHome = ()=> {
    const [query, setQuery] = useState({
        page: 1, 
        limit: 10,
        searchParams: "",
        status: "open"
    });
    const [jobData, setJobData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const isCompany = true;

    const colorsArray = [
        "#3699FF",
        "#195ea3",
        "#6b6bf5"
    ];

    function getRandomColorFromArray(colors) {
        // Generate a random index based on the length of the colors array
        const randomIndex = Math.floor(Math.random() * colors.length);
        // Return the color at the random index
        return colors[randomIndex];
    }

    const updateData = async (data)=> {
        let newData = [];
        if (data.length > 0) {
            await data.map((element)=> {
                let updatedElement = {...element};
                updatedElement.isCompany = true;
                updatedElement.color = getRandomColorFromArray(colorsArray);
                newData.push(updatedElement);
            });
        }
        return newData
    }

    let getRequirementList = async (newQuery)=> {
        try {
            setIsLoading(true);
            const getRes = await postReq("/company/requirement/list", newQuery);
            if (getRes.status == 1) {
                let newData = await updateData(getRes.data.requirements);
                setJobData(newData);
                // toastNotification('success', getRes.message);
                setIsLoading(false)
            } else {
                setJobData([]);
                toastNotification('error', getRes.message);
                setIsLoading(false)
            }
        } catch (error) {
            console.log('error ', error)
            setJobData([]);
            //toastNotification("error", "Something went wrong");
            setIsLoading(false)
        }
    }

    useEffect(()=> {
        getRequirementList(query);
    }, [])

    const handleOnChange = (event) => {
        const value = event.target.value;
        let data = {...query}
        data.searchParams = value
        searchData(data);
    }

    const searchData = useCallback(
        _.debounce((value) => {
            console.log('Searching for:', value);
            getRequirementList(value);
        }, 500), // 500ms debounce time
        []
    );

    const handleClose = ()=> {
        setShowModal(false)
    }
    
    return (
        <>
            <div className="vendorDashboard">
                <div className="row justify-content-between searchId">
                    <div className="col-md-8 col-lg-8 col-xs-6 mt-2 mb-5 d-flex justify-content-end align-items-center">
                        <div className="input-group w-340">
                            <input type="text" className="form-control" placeholder="Search By Job ID" onChange={handleOnChange} />
                            <div className="input-group-append newBackColor">
                            <button className="btn btn-primary" type="button">
                                <i className="fa fa-search"></i>
                            </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-lg-3 col-xs-6 mt-2 mb-5 text-right">
                        <button
                            type="button"
                            onClick={() => setShowModal(true)}
                            className={`btn btn-primary font-weight-bold mr-2`}
                            >
                            <FaPlus /> Add New Requirement
                        </button>
                    </div>
                </div>
                <div className="row justify-content-center vendorWrapperId">
                    {!isLoading ? (
                        jobData?.length > 0 ? (
                            jobData.map((data, index)=> (
                                <JobView {...data} key={index} />
                            ))
                        ) : (
                            <div> No posted requirement found!!</div>
                        )
                    ): <Loading/>}
                </div>
                <Modal className="apply-job-modal" show={showModal} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Post Your Requirement</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddRequirement getRequirement={getRequirementList} query={query} handleClose={handleClose}/>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default withRouter(CompanyHome);