/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useMemo} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import objectPath from "object-path";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {UserProfileDropdown} from "./dropdowns/UserProfileDropdown";
import useUserDetails from "../../../../helperFunc/useUserDetails";

export function QuickUserToggler() {
  const { userDetails } = useUserDetails();
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);

  function getInitials(name) {
    if (name.includes(" ")) {
        // Split the name by space
        const nameParts = name.split(" ");
        // Get the first character of the first two parts
        const firstInitial = nameParts[0].charAt(0);
        const secondInitial = nameParts[1].charAt(0);
        return firstInitial + secondInitial;
    } else {
        // Get the first two characters of the single name part
        return name.charAt(0) + name.charAt(1);
    }
  }

  function getFirstName(name) {
    // Split the name by space and return the first part
    const nameParts = name.split(" ");
    return nameParts[0];
  }

  return (<>
        {layoutProps.offcanvas && (<OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="quick-user-tooltip">View user</Tooltip>}
        >
          <div className="topbar-item">
            <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                 id="kt_quick_user_toggle">
              <>

                <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
                <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                  {userDetails?.name && getFirstName(userDetails?.name)}
                </span>
                <span className="symbol symbol-35 symbol-light-success">                
                    <span className="symbol-label font-size-h5 font-weight-bold text-uppercase">{userDetails?.name && getInitials(userDetails?.name)}</span>
                </span>
              </>
            </div>
          </div>
        </OverlayTrigger>)}
        {!layoutProps.offcanvas && (<UserProfileDropdown/>)}
      </>
  );
}
