import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import post from "../api";
import toastNofication from '../../../../helperFunc/toast';
import { useHistory } from 'react-router-dom'

const initialValues = {
  name: "",
  establishmentYear: "",
  companyEmail: "",
  password: "",
  companyStrength: "",
  companyWebsite: "",
  technologyNiche: [],
  contactPerson: "",
  paymentCycle: "",
  // ndaDocument: File,
  // msaDocument: File
};

function CompanyRegistration(props) {
  const { intl } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [techArray, setTechArray] = useState();
  const RegistrationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required(
        "Company Name is required"
      ),
    companyEmail: Yup.string()
      .email("Wrong email format")
      .required(
        "Company email is required"
      ),
    establishmentYear: Yup.string()
      .min(4, "Enter full year")
      .max(4, "Enter full year")
      .matches(/[0-9]{4}/, "Enter a valid year")
      .test("establishmentYear", "Establishment year must be less than current year", (value) => {
        let currentYear = new Date().getFullYear();
        if (currentYear >= parseInt(value)) {
          return true
        } else {
          return false
        }
      })
      .required(
        "Establishbent Year is required"
      ),
    companyStrength: Yup.string()
      .min(2, "Company strength is too low")
      .max(7, "Company strength is too big")
      .required(
        "Company strength is required"
      ).matches(/[0-9]/, "Company strength should only be in numbers"),
    companyWebsite: Yup.string()
      .required(
        "Company website url is required"
      )
      .matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/gi,
        "Invalid website url, Please enter correct one to continue"),
    technologyNiche: Yup.array().of(Yup.string()).min(1, "Technology niche is required").max(10, "You can add atmost 10 niches").required("Technology niche is required"),
    contactPerson: Yup.string().email("Invalid email").required("Contact person email is required"),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        "Password is required"
      ),
    changepassword: Yup.string()
      .required(
        "Change password is required"
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
    paymentCycle: Yup.string().required('required')
    .matches(/[0-9]/, "Payment Cycle should only be in numbers"),
    // ndaDocument: Yup.mixed().required('required')
    //   .test('fileFormat', 'Only PDF files are allowed', value => {
    //     if (value) {
    //       const supportedFormats = ['pdf'];
    //       return supportedFormats.includes(value.name.split('.').pop());
    //     }
    //     return true;
    //   })
    //   .test('fileSize', 'File size must be less than 3MB', value => {
    //     if (value) {
    //       return value.size <= 3145728;
    //     }
    //     return true;
    //   }),
    // msaDocument: Yup.mixed().required('required')
    //   .test('fileFormat', 'Only PDF files are allowed', value => {
    //     if (value) {
    //       const supportedFormats = ['pdf'];
    //       return supportedFormats.includes(value.name.split('.').pop());
    //     }
    //     return true;
    //   })
    //   .test('fileSize', 'File size must be less than 3MB', value => {
    //     if (value) {
    //       return value.size <= 3145728;
    //     }
    //     return true;
    //   }),
  });


  const handleTechnologyNiche = (techData) => {
    console.log('tech data ', techData)
    if (techData !== null) {
      setTechArray(techData);
      let updatedTech = []
      if (techData.length > 0) {
        techData.map((tech) => {
          updatedTech.push(tech.label)
        })
      }
      console.log('tech ', updatedTech)
      formik.setFieldValue('technologyNiche', updatedTech)
    } else {
      formik.setFieldValue('technologyNiche', []);
      setTechArray([])
    }
  }

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const handleFormSubmission = async (data) => {
    try {
      //console.log('data ', data)
      const postData = await post('/company/register', data, {
        "Content-Type": "multipart/form-data",
      });
      if (postData.status == 1) {
        toastNofication('success', postData.message);
        formik.resetForm();
        setTechArray([]);
        disableLoading();
        history.push("/auth/company/login")
      } else {
        toastNofication('error', postData.message);
        formik.resetForm();
        disableLoading();
        setTechArray([])
      }
    } catch (error) {
      toastNofication('error', "Something went wrong, Try again");
      disableLoading();
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      const formData = new FormData();
      formData.append('name', values.name)
      formData.append('establishmentYear', values.establishmentYear)
      formData.append('companyEmail', values.companyEmail)
      formData.append('companyStrength', values.companyStrength)
      formData.append('companyWebsite', values.companyWebsite)
      formData.append('contactPerson', values.contactPerson)
      formData.append('technologyNiche', values.technologyNiche)
      formData.append('password', values.password)
      formData.append('paymentCycle', parseInt(values.paymentCycle))
      // formData.append('ndaDoc', values.ndaDocument)
      // formData.append('msaDoc', values.msaDocument)
      await handleFormSubmission(formData)
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          Company Onboarding
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your company details to get registered for this tool
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: Fullname */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Full name"
            type="text"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "name"
            )}`}
            name="name"
            {...formik.getFieldProps("name")}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.name}</div>
            </div>
          ) : null}
        </div>
        {/* end: Fullname */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "companyEmail"
            )}`}
            name="companyEmail"
            {...formik.getFieldProps("companyEmail")}
          />
          {formik.touched.companyEmail && formik.errors.companyEmail ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.companyEmail}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Company Establishment Year"
            type="text"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "establishmentYear"
            )}`}
            name="establishmentYear"
            {...formik.getFieldProps("establishmentYear")}
          />
          {formik.touched.establishmentYear && formik.errors.establishmentYear ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.establishmentYear}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Company strength"
            type="text"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "companyStrength"
            )}`}
            name="companyStrength"
            {...formik.getFieldProps("companyStrength")}
          />
          {formik.touched.companyStrength && formik.errors.companyStrength ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.companyStrength}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Company website"
            type="text"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "companyWebsite"
            )}`}
            name="companyWebsite"
            {...formik.getFieldProps("companyWebsite")}
          />
          {formik.touched.companyWebsite && formik.errors.companyWebsite ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.companyWebsite}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "contactPerson"
            )}`}
            name="contactPerson"
            {...formik.getFieldProps("contactPerson")}
          />
          {formik.touched.contactPerson && formik.errors.contactPerson ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.contactPerson}</div>
            </div>
          ) : null}
        </div>


        {/* begin: Username */}
        {/* <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="User name"
            type="text"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "username"
            )}`}
            name="username"
            {...formik.getFieldProps("username")}
          />
          {formik.touched.username && formik.errors.username ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.username}</div>
            </div>
          ) : null}
        </div> */}
        {/* end: Username */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Confirm Password"
            type="password"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            {...formik.getFieldProps("changepassword")}
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.changepassword}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Payment Cycle"
            type="text"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "paymentCycle"
            )}`}
            name="paymentCycle"
            {...formik.getFieldProps("paymentCycle")}
          />
          <small class="w-100"><b>Note: Enter payment cycle in days</b></small>
          {formik.touched.paymentCycle && formik.errors.paymentCycle ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.paymentCycle}</div>
            </div>
          ) : null}
        </div>

        {/* <div className="form-group fv-plugins-icon-container">
        <p style={{"marginBottom":"0.5rem"}}>Upload your NDA Document</p>
          <input
            placeholder="Upload NDA document"
            type="file"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "ndaDocument"
            )}`}
            name="ndaDocument"
            accept=".pdf"
            onChange={(e) => formik.setFieldValue('ndaDocument', e.target.files[0])}
          />
          {formik.touched.ndaDocument && formik.errors.ndaDocument ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.ndaDocument}
              </div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <p style={{"marginBottom":"0.5rem"}}>Upload your MSA Document</p>
          <input
            placeholder="Upload MSA document"
            type="file"
            accept='.pdf'
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "msaDocument"
            )}`}
            name="msaDocument"
            onChange={(e) => formik.setFieldValue("msaDocument", e.target.files[0])}
          />
          {formik.touched.msaDocument && formik.errors.msaDocument ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.msaDocument}
              </div>
            </div>
          ) : null}
        </div> */}

        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="button"
            disabled={formik.isSubmitting || !(formik.dirty && formik.isValid)}
            onClick={() => formik.handleSubmit()}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/company/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default CompanyRegistration;
