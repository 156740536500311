import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import toastNotification from "../../../../helperFunc/toast";
import { postReq } from "../../../api";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Document, Page, pdfjs } from 'react-pdf';
import { ListGroup } from "react-bootstrap";
import "./styles.css"
import UpdateStatus from "../../../components/updateApplicationStatusModal";
import {useHistory} from 'react-router-dom'

const ViewApplications = () => {
    console.log('we are in the view applicatins')
    const [isLoading, setIsLoading] = useState();
    const history = useHistory();
    const [query, setQuery] = useState({
        limit: 10,
        page: 1
    });
    const [applicationDetails, setApplicationDetails] = useState([])
    const { id } = useParams();

    const backToDeviceList = () => {
        history.push(`/company/job-details/${id}`);
      };

    const getProfileShorlistedText = (type) => {
        console.log('tyep in profile tsxgt')
        switch (type) {
            case "selected":
                return "Profile Selected"
                break;
            case "inprocess":
                return "Profile In-Process"
                break;
            case "rejected":
                return "Profile Rejected"
                break;
            case "onhold":
                return "Profile OnHold"
                break;
            default:
                break;
        }
    }

    const getCvShortlistedText = (type) => {
        switch (type) {
            case "shortlisted":
                return "Cv Shortlisted"
                break;
            case "rejected":
                return "Cv Rejected"
                break;
            case "onhold":
                return "Cv OnHold"
                break;
            default:
                return "Cv Pending"
                break;
        }
    }
    const getProfileShorlistedClass = (type) => {
        switch (type) {
            case "selected":
                return "profileSelectedTag"
                break;
            case "inprocess":
                return "profileInprocessTag"
                break;
            case "rejected":
                return "profileRejectedTag"
                break;
            case "onhold":
               return "profileOnHoldTag"
                break;
            default:
                break;
        }
    }

    const getCvShortlistedClass = (type) => {
        switch (type) {
            case "shortlisted":
                return "cvShortlistedTag"
                break;
            case "rejected":
                return "cvRejectedTag"
                break;
            case "onhold":
                return "cvOnHoldTag"
                break;
            default:
                return "cvPendingTag"
                break;
        }
    }

    const getApplications = async () => {
        try {
            setIsLoading(true)
            const getJob = await postReq("/company/requirement/application-list", { ...query, requirementId: id });
            console.log('getJob ', getJob)
            if (getJob.status == 1) {
                setApplicationDetails(getJob?.data?.appliedRequirements);
                setIsLoading(false);
                console.log(getJob.data.appliedRequirements);
            } else {
                setApplicationDetails([]);
                setIsLoading(false);
                toastNotification('error', getJob.message);
            }

        } catch (error) {
            console.log('error ', error);
            toastNotification('error', "Something went wrong")
        }
    }

    const [isProfile, setIsProfile] = useState();
    const [showModal, setShowModal] = useState();
    const [selectedRequirementId, setSelectedRequirementId] = useState();
    const [currentStatus, setCurrentStatus] = useState();

    const handleClose = ()=> {
        setShowModal(false)
    }

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        console.log("dlfhasldkfhsl")
        getApplications()
    }, [])
    return (
        <>
            <div className="viewApplications">
                <div className="row my-5">
                <div className='backBtnWrapper' onClick={()=> backToDeviceList()}>
                            <button className='backBtn'>{"Back"}</button>
                        </div>
                    {showModal ? (
                        <>
                            <UpdateStatus
                                requirementId={selectedRequirementId}
                                currentStatus={currentStatus}
                                handleClose={handleClose}
                                show={showModal}
                                isProfile={isProfile}
                                fetchData = {getApplications}
                            />
                        </>
                    ): null}
                    {applicationDetails?.length > 0 ?
                        (applicationDetails.map((application) => (
                            <>
                                <div className="col-md-6 col-xs-12 col-lg-6 my-3">
                                    <Card style={{ width: '90%', borderRadius: "12px" }}>
                                        <Card.Body>
                                            <div className="cardHeaderWrapper">
                                                <Card.Title>Application</Card.Title>
                                            <div className="applicationTagWrapper">
                                                {(application.cvScreeningStatus != "rejected" && application.cvScreeningStatus != "shortlisted") ?
                                                    <div className={getCvShortlistedClass(application.cvScreeningStatus)}>
                                                        <p className="tags">{getCvShortlistedText(application.cvScreeningStatus)}</p>
                                                    </div>
                                                : 
                                                    <>
                                                    <div className={getCvShortlistedClass(application.cvScreeningStatus)}>
                                                        <p className="tags">{getCvShortlistedText(application.cvScreeningStatus)}</p>
                                                    </div>

                                                    <div className={getProfileShorlistedClass(application.profileStatus)}>
                                                        <p className="tags">{getProfileShorlistedText(application.profileStatus)}</p>
                                                    </div>
                                                    </>
                                                }
                                            </div>
                                            </div>
                                            <ListGroup style={{ "marginBottom": "2rem" }}>
                                                <ListGroup.Item><b>Requirement Name:</b> {application.requirementDetails.requirementName}</ListGroup.Item>
                                                <ListGroup.Item><b>Vendor Name:</b> {application.vendorDetails.name}</ListGroup.Item>
                                                <ListGroup.Item><b>Resume:</b> <a target="_blank" href={application.resumeLink}>Link</a></ListGroup.Item>
                                                <ListGroup.Item><b>Vendor Budget:</b> {application.budget}</ListGroup.Item>
                                            </ListGroup>
                                            {application.cvScreeningStatus !== "rejected" ? (
                                                (application.cvScreeningStatus == "shortlisted") ? (
                                                    <>
                                                        <Button style={{ "marginRight": "1rem" }} disabled variant="success">Cv Shortlisted</Button>
                                                        {application.profileStatus != "rejected" ? (
                                                            (application.profileStatus == "selected" ?
                                                                (
                                                                    <>
                                                                        <Button style={{"marginRight":"1rem"}} variant="success" disabled> Profile Selected </Button>
                                                                        <Button variant="primary">Download SOW</Button>
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <Button onClick={()=> {setIsProfile(true); setShowModal(true); setSelectedRequirementId(application._id); setCurrentStatus(application.profileStatus)}} variant="primary">Update Profile Status</Button>
                                                                )
                                                            )
                                                        ) : (
                                                            <Button variant="danger" disabled>Profile Rejected</Button>
                                                        )}

                                                    </>
                                                ) : (
                                                    <>
                                                        <Button onClick={()=> {setIsProfile(false); setShowModal(true); setSelectedRequirementId(application._id); setCurrentStatus(application.cvScreeningStatus)}} variant="primary">Update Cv Status</Button>
                                                    </>
                                                )

                                            ) : (
                                                <Button variant="danger" disabled>Cv Rejected</Button>
                                            )}

                                        </Card.Body>
                                    </Card>
                                </div>
                            </>
                        ))) : <div>
                            <p>No Applications for this job</p>
                            </div>}
                </div>
            </div>
        </>
    )
}

export default ViewApplications;