import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from "yup";
import post from "../api";
import toastNofication from "../../../../helperFunc/toast";
import { postReq } from "../../../api";

const initialValues = {
  name: "",
  vendorEmail: "",
  vendorCompanyName: "",
  // password: "",
  vendorCompanyStrength: "",
  vendorCompanyWebsiteUrl: "",
  technologyNiche: [],
  msmeNo: "",
  cinNO: "",
  tanNo: "",
  gstStatementFile: "",
  ceoPanNo: "",
  ceoAdharNo: "",
  RegAddress: "",
  operationalAddress: "",
  isoNo: "",
  communicationEmail: "",
  organizationLogo: "",
  itrNo1: "",
  itrNo2: "",
  itrNo3: "",
  vendorCompanyGstNo: "",
};

const RegistrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 30 characters")
    .required("Name is required"),
  vendorEmail: Yup.string()
    .email("Wrong email format")
    .required("Email is required"),
  vendorCompanyGstNo: Yup.string()
    .required('Gst no is required'),
  // password: Yup.string()
  //   .min(3, "Minimum 8 characters")
  //   .max(50, "Maximum 20 characters")
  //   .required("Password is required"),
  vendorCompanyName: Yup.string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 30 characters")
    .required("Company name is required"),
  vendorCompanyStrength: Yup.string()
    .min(2, "Company strength is too low")
    .max(7, "Company strength is too big")
    .required("Company strength is required")
    .matches(/[0-9]/, "Company strength should only be in numbers"),
  vendorCompanyWebsiteUrl: Yup.string()
    .required("Company website url is required")
    .matches(
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/gi,
      "Invalid website url, Please enter correct one to continue"
    ),
  technologyNiche: Yup.array()
    .of(Yup.string())
    .min(1, "Technology niche is required")
    .max(3, "You can add atmost 3 niches")
    .required("Technology niche is required"),
  msmeNo: Yup.string()
    .matches(/^([UDYAM]{5})-([A-Z]{2})-([0-9]{2})-([0-9]{7})$/, "Invalid MSME number format")
    .required("MSME number is required"),
  cinNO: Yup.string()
    .matches(/^([A-Za-z]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/, "Invalid CIN number format")
    .required("CIN number is required"),
  tanNo: Yup.string().required("TAN number is required"),
  ceoPanNo: Yup.string()
    .matches(/(?:(?=(^[a-zA-Z]{5}\d{4}[a-zA-Z]{1}$))|(?=(^[a-zA-Z]{4}[0-9]{5}[a-zA-Z]{1}?$)))/, "Invalid PAN number format")
    .required("PAN is required"),
  ceoAdharNo: Yup.string()
    .matches(/^\d{12}$/, "Invalid Adhar number format")
    .required("Adhar is required"),
  RegAddress: Yup.string().required("register address is required"),
  operationalAddress: Yup.string().required("Operational address is required"),
  gstStatementFile: Yup.string().required(
    "Please upload your GST statements from the last 6 months."
  ),
  organizationLogo: Yup.mixed().test('fileType', 'Only PNG images are allowed', (value) => {
    if (!value) return true;
    const supportedFormats = ['image/png'];
    const fileExtension = value.name.split('.').pop(); // Get the file extension
    return supportedFormats.includes(`image/${fileExtension}`);
  }),
  itrNo1: Yup.string().matches(/[0-9]/, "ITR no should only be in numbers").required("ITR no is required"),
  itrNo2: Yup.string().matches(/[0-9]/, "ITR no should only be in numbers").required("ITR no is required"),
  itrNo3: Yup.string().matches(/[0-9]/, "ITR no should only be in numbers").required("ITR no is required"),
});

function VendorOnboarding() {
  const { id } = useParams();
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [techArray, setTechArray] = useState();
  const [touched, setTouched] = useState(false);
  const [techNicheOptions, setTechNicheOptions] = useState([]);
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const handleTechnologyNiche = (techData) => {
    console.log('tech data ', techData)
    if (techData !== null) {
      setTechArray(techData);
      let updatedTech = [];
      if (techData.length > 0) {
        techData.map((tech) => {
          updatedTech.push(tech._id);
        });
      }
      console.log('updated Tech ', updatedTech)
      formik.setFieldValue("technologyNiche", updatedTech);
    } else {
      formik.setFieldValue("technologyNiche", []);
      setTechArray([]);
    }
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      const formData = new FormData();
      formData.append('companyId', id)
      formData.append('name', values.name)
      formData.append('vendorEmail', values.vendorEmail)
      formData.append('vendorCompanyName', values.vendorCompanyName)
      formData.append('vendorCompanyStrength', values.vendorCompanyStrength)
      formData.append('vendorCompanyWebsiteUrl', values.vendorCompanyWebsiteUrl)
      formData.append('msmeNo', values.msmeNo)
      for (let i = 0; i < values.technologyNiche.length; i++) {
        formData.append(
          `technologyNiche[${i}]`,
          values.technologyNiche[i]
        );
      }
      //formData.append('technologyNiche', values.technologyNiche)
      formData.append('cinNO', values.cinNO)
      formData.append('tanNo', values.tanNo)
      formData.append('ceoPanNo', values.ceoPanNo);
      formData.append('ceoAdharNo', values.ceoAdharNo);
      formData.append('RegAddress', values.RegAddress);
      formData.append('operationalAddress', values.operationalAddress);
      formData.append('isoNo', values.isoNo);
      formData.append('communicationEmail', values.communicationEmail);
      formData.append('organizationLogo', values.organizationLogo);
      formData.append('gstStatementFile', values.gstStatementFile);
      formData.append('itrNo1', values.itrNo1);
      formData.append('itrNo2', values.itrNo2);
      formData.append('itrNo3', values.itrNo3);
      formData.append('vendorCompanyGstNo', values.vendorCompanyGstNo)
      try {
        //console.log('data ', data)
        const postData = await post('/vendor/register', formData, {
          "Content-Type": "multipart/form-data",
        });
        if (postData.status == 1) {
          toastNofication('success', postData.message);
          formik.resetForm();
          setTechArray([]);
          disableLoading();
          setTimeout(() => {
            history.push("/auth/vendor/login")
          }, 2000);
        } else {
          toastNofication('error', postData.message);
          formik.resetForm();
          setTechArray([]);
          disableLoading();
        }
  
      } catch (error) {
        toastNofication('error', "Something went wrong, Try again");
        disableLoading();
      }
    },
  });

  const handleBlur = () => {
    if (!touched) {
      setTouched(true);
    }
  };

  console.log(formik.errors);

  const handleTagsData = async () => {
    try {

      const callResponse = await postReq("/common/getTags", { type: "techNiche", companyId: id });

      if (callResponse.status == 1) {
          setTechNicheOptions(callResponse.data)
          console.log('call response techNiche ', callResponse.data)
      }

    } catch (error) {
      console.log('error ', error);
    }
  }

  useEffect(()=> {
    handleTagsData();
  }, [])

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">New Vendor Onboarding</h3>
        <p className="text-muted font-weight-bold">
          Enter your Vendor details to get registered for company
        </p>
      </div>
      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp vendor-onboarding-page"
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}
        {/* begin: Fullname */}
        <div className="form-group fv-plugins-icon-container">
        <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>Vendor Name <span style={{'color':"red"}}>*</span></p>
          <input
            placeholder="Vendor name"
            type="text"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "name"
            )}`}
            name="name"
            {...formik.getFieldProps("name")}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.name}</div>
            </div>
          ) : null}
        </div>
        {/* end: Fullname */}
        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
        <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>Vendor Email <span style={{'color':"red"}}>*</span></p>
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "vendorEmail"
            )}`}
            name="vendorEmail"
            {...formik.getFieldProps("vendorEmail")}
          />
          {formik.touched.vendorEmail && formik.errors.vendorEmail ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.vendorEmail}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}
        {/* begin: Password */}
        {/* <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div> */}
        {/* end: Password */}
        <div className="form-group fv-plugins-icon-container">
        <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>Vendor Company Name <span style={{'color':"red"}}>*</span></p>
          <input
            placeholder="Company Name"
            type="text"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "vendorCompanyName"
            )}`}
            name="vendorCompanyName"
            {...formik.getFieldProps("vendorCompanyName")}
          />
          {formik.touched.vendorCompanyName && formik.errors.vendorCompanyName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.vendorCompanyName}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
        <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>Company Strength <span style={{'color':"red"}}>*</span></p>
          <input
            placeholder="Company strength"
            type="text"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "vendorCompanyStrength"
            )}`}
            name="vendorCompanyStrength"
            {...formik.getFieldProps("vendorCompanyStrength")}
          />
          {formik.touched.vendorCompanyStrength &&
          formik.errors.vendorCompanyStrength ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.vendorCompanyStrength}
              </div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
        <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>Company Website <span style={{'color':"red"}}>*</span></p>
          <input
            placeholder="Company website"
            type="text"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "vendorCompanyWebsiteUrl"
            )}`}
            name="vendorCompanyWebsiteUrl"
            {...formik.getFieldProps("vendorCompanyWebsiteUrl")}
          />
          {formik.touched.vendorCompanyWebsiteUrl &&
          formik.errors.vendorCompanyWebsiteUrl ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.vendorCompanyWebsiteUrl}
              </div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
        <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>Technology Niche <span style={{'color':"red"}}>*</span></p>
          <Select
            getOptionLabel={option => option.value}
            getOptionValue={option => option._id}
            placeholder="Technology Niche"
            options={techNicheOptions}
            isMulti
            onChange={(e) => handleTechnologyNiche(e)}
            name="technologyNiche"
            onBlur={(e) => formik.handleBlur(e)}
            value={techArray}
          />
          {formik.touched.technologyNiche && formik.errors.technologyNiche ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.technologyNiche}
              </div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
        <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>MSME No <span style={{'color':"red"}}>*</span></p>
          <input
            placeholder="MSME No"
            type="text"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "msmeNo"
            )}`}
            name="msmeNo"
            {...formik.getFieldProps("msmeNo")}
          />
          {formik.touched.msmeNo && formik.errors.msmeNo ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.msmeNo}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
        <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>CIN No <span style={{'color':"red"}}>*</span></p>
          <input
            placeholder="CIN No"
            type="text"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "cinNO"
            )}`}
            name="cinNO"
            {...formik.getFieldProps("cinNO")}
          />
          {formik.touched.cinNO && formik.errors.cinNO ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.cinNO}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
        <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>TAN No <span style={{'color':"red"}}>*</span></p>
          <input
            placeholder="TAN No"
            type="text"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "tanNo"
            )}`}
            name="tanNo"
            {...formik.getFieldProps("tanNo")}
          />
          {formik.touched.tanNo && formik.errors.tanNo ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.tanNo}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
        <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>PAN No <span style={{'color':"red"}}>*</span></p>
          <input
            placeholder="Vendor Company PAN No"
            type="text"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "ceoPanNo"
            )}`}
            name="ceoPanNo"
            {...formik.getFieldProps("ceoPanNo")}
          />
          {formik.touched.ceoPanNo && formik.errors.ceoPanNo ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.ceoPanNo}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
        <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>GST No <span style={{'color':"red"}}>*</span></p>
          <input
            placeholder="Vendor Company GST No"
            type="text"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "vendorCompanyGstNo"
            )}`}
            name="ceoPanNo"
            {...formik.getFieldProps("vendorCompanyGstNo")}
          />
          {formik.touched.vendorCompanyGstNo && formik.errors.vendorCompanyGstNo ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.vendorCompanyGstNo}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
        <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>Aadhar Card No <span style={{'color':"red"}}>*</span></p>
          <input
            placeholder="Director Adhar No"
            type="text"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "ceoAdharNo"
            )}`}
            name="ceoAdharNo"
            {...formik.getFieldProps("ceoAdharNo")}
          />
          {formik.touched.ceoAdharNo && formik.errors.ceoAdharNo ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.ceoAdharNo}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
        <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>Register Address <span style={{'color':"red"}}>*</span></p>
          <input
            placeholder="Register Address"
            type="text"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "RegAddress"
            )}`}
            name="RegAddress"
            {...formik.getFieldProps("RegAddress")}
          />
          {formik.touched.RegAddress && formik.errors.RegAddress ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.RegAddress}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
        <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>Operational Address <span style={{'color':"red"}}>*</span></p>
          <input
            placeholder="Operational Address"
            type="text"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "operationalAddress"
            )}`}
            name="operationalAddress"
            {...formik.getFieldProps("operationalAddress")}
          />
          {formik.touched.operationalAddress &&
          formik.errors.operationalAddress ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.operationalAddress}
              </div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
        <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>ISO Certification No <span style={{'color':"red"}}>*</span></p>
          <input
            placeholder="ISO Certification No"
            type="text"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "isoNo"
            )}`}
            name="isoNo"
            {...formik.getFieldProps("isoNo")}
          />
          {formik.touched.isoNo && formik.errors.isoNo ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.isoNo}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
        <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>Communication Email <span style={{'color':"red"}}>*</span></p>
          <input
            placeholder="Communication Email"
            type="email"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "communicationEmail"
            )}`}
            name="communicationEmail"
            {...formik.getFieldProps("communicationEmail")}
          />
          {formik.touched.communicationEmail &&
          formik.errors.communicationEmail ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.communicationEmail}
              </div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>ITR No <span style={{'color':"red"}}>*</span></p>
          <div className="row">
            <div className="col-md-6 col-lg-6 col-xs-6">
              <input
                placeholder="ITR No 1"
                type="text"
                className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
                  "itrNo1"
                )}`}
                name="itrNo1"
                {...formik.getFieldProps("itrNo1")}
              />
              {formik.touched.itrNo1 && formik.errors.itrNo1 ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.itrNo1}</div>
                </div>
              ) : null}
            </div>
            <div className="col-md-6 col-lg-6 col-xs-6">
              <input
                placeholder="ITR No 2"
                type="text"
                className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
                  "itrNo2"
                )}`}
                name="itrNo2"
                {...formik.getFieldProps("itrNo2")}
              />
              {formik.touched.itrNo2 && formik.errors.itrNo2 ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.itrNo2}</div>
                </div>
              ) : null}
            </div>
            <div className="col-md-6 col-lg-6 col-xs-6 mt-4">
              <input
                placeholder="ITR No 3"
                type="text"
                className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
                  "itrNo3"
                )}`}
                name="itrNo3"
                {...formik.getFieldProps("itrNo3")}
              />
              {formik.touched.itrNo3 && formik.errors.itrNo3 ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.itrNo3}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="form-group fv-plugins-icon-container">
          <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>Upload Organization Logo <span style={{'color':"red"}}>*</span></p>
          <input
            placeholder="Upload Organization Logo"
            type="file"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "organizationLogo"
            )}`}
            name="organizationLogo"
            accept=".png"
            onChange={(e) => {
              setTouched(true);
              formik.setFieldValue("organizationLogo", e.target.files[0]);
            }}
            onBlur={handleBlur}
          />
          {touched && formik.errors.organizationLogo ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.organizationLogo}
              </div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>Upload GST Statements <span style={{'color':"red"}}>*</span></p>
          <input
            placeholder="Upload GST Statements"
            type="file"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "gstStatementFile"
            )}`}
            name="gstStatementFile"
            accept=".pdf"
            onChange={(e) =>
              formik.setFieldValue("gstStatementFile", e.target.files[0])
            }
          />
          {formik.touched.gstStatementFile && formik.errors.gstStatementFile ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.gstStatementFile}
              </div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="button"
            disabled={formik.isSubmitting || !(formik.dirty && formik.isValid)}
            onClick={() => formik.handleSubmit()}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
    </div>
  );
}

export default VendorOnboarding;
