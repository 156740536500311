import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { showConfirmDialog } from "../../../helperFunc/helper";
import toastNotification from "../../../helperFunc/toast";
import { postReq } from "../../api";

const VendorModal = ({ vendorData, show, handleClose }) => {
    const [approveLoading, setApproveLoading] = useState(false);
    // click to approve
    const clickToApprove = async (id) => {
        const confirmed = await showConfirmDialog(
            "Confirm!",
            "Are you sure you want to approve vendor?"
        );
        if (confirmed) {
            setApproveLoading(true);
            const response = await postReq(`/company/update-vendor-request`, {
                approveId: id,
                status: "approved"
            });
            if (response.status === 1) {
                toastNotification("success", response.message);
                handleClose();
                setApproveLoading(false);
                window.location.reload();
            } else {
                toastNotification("error", response.message);
                setApproveLoading(false);
            }
        }
    }

    // click to reject
    const clickToReject = async (id) => {
        const confirmed = await showConfirmDialog(
            "Confirm!",
            "Are you sure you want to reject vendor?"
        );
        if (confirmed) {
            const response = await postReq(`/company/update-vendor-request`, {
                approveId: id,
                status: "rejected"
            });
            if (response.status === 1) {
                toastNotification("success", response.message);
            } else {
                toastNotification("error", response.message);
            }
        }
    }

    // approve nda/msa
    const clickToApproveDoc = async (documentId, vendorId) => {
        const confirmed = await showConfirmDialog(
            "Confirm!",
            "Are you sure you want to approve NDA/MSA?"
        );
        if (confirmed) {
            const response = await postReq(`/company/approve-documents`, {
                documentId: documentId,
                vendorId: vendorId,
                approvedByCompany: "approved"
            });
            console.log(response);
            if (response.status === 1) {
                toastNotification("success", response.message);
                handleClose();
            } else {
                toastNotification("error", response.message);
            }
        }
    }

    const clickToRejectDoc = async (documentId, vendorId) => {
        const confirmed = await showConfirmDialog(
            "Confirm!",
            "Are you sure you want to reject vendor?"
        );
        if (confirmed) {
            const response = await postReq(`/company/approve-documents`, {
                documentId: documentId,
                vendorId: vendorId,
                approvedByCompany: "rejected"
            });
            console.log(response);
            if (response.status === 1) {
                toastNotification("success", response.message);
                handleClose();
            } else {
                toastNotification("error", response.message);
            }
        }
    }

    return (
        <Modal
            show={show}
            className="apply-job-modal"
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Vendor Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-6">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <div className="d-flex align-items-center">
                                    <p className="mb-1 vendor-title font-weight-bold">Name: </p>
                                    <span className="ml-2">{vendorData?.vendorId?.name}</span>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="d-flex align-items-center">
                                    <p className="mb-1 vendor-title font-weight-bold">MSME NO: </p>
                                    <span className="ml-2">{vendorData?.vendorId?.msmeNo}</span>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="d-flex align-items-center">
                                    <p className="mb-1 vendor-title font-weight-bold">CIN NO: </p>
                                    <span className="ml-2">{vendorData?.vendorId?.cinNO}</span>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="d-flex align-items-center">
                                    <p className="mb-1 vendor-title font-weight-bold">CEO Pan NO: </p>
                                    <span className="ml-2">{vendorData?.vendorId?.ceoPanNo}</span>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="d-flex align-items-center">
                                    <p className="mb-1 vendor-title font-weight-bold">Reg Address: </p>
                                    <span className="ml-2">{vendorData?.vendorId?.RegAddress}</span>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="d-flex align-items-center">
                                    <p className="mb-1 vendor-title font-weight-bold">Com. Email: </p>
                                    <a href={`mailto:${vendorData?.vendorId?.communicationEmail}`} className="ml-2">{vendorData?.vendorId?.communicationEmail}</a>
                                </div>
                            </li>
                            {vendorData?.documents && vendorData?.documents?.isDocSigned && <li className="list-group-item">
                                <div className="d-flex align-items-center">
                                    <p className="mb-1 vendor-title font-weight-bold">NDA: </p>
                                    <a href={vendorData?.documents?.unsignedNda} target="blank" className="ml-2">Click to view NDA</a>
                                </div>
                            </li>}
                            <li className="list-group-item">
                                <div className="d-flex align-items-center">
                                    <p className="mb-1 vendor-title font-weight-bold">Vendor Status: </p>
                                    <span href={vendorData?.status} target="blank" className={`ml-2 badge ${vendorData?.status == 'pending' ? `badge-warning` : vendorData?.status == 'approved' ? `badge-success` : `badge-danger`}`}>{vendorData?.status}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <div className="d-flex align-items-center">
                                    <p className="mb-1 vendor-title font-weight-bold">Email: </p>
                                    <a href={`mailto:${vendorData?.vendorId?.vendorEmail}`} className="ml-2">{vendorData?.vendorId?.vendorEmail}</a>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="d-flex align-items-center">
                                    <p className="mb-1 vendor-title font-weight-bold">TAN NO: </p>
                                    <span className="ml-2">{vendorData?.vendorId?.tanNo}</span>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="d-flex align-items-center">
                                    <p className="mb-1 vendor-title font-weight-bold">ISO NO: </p>
                                    <span className="ml-2">{vendorData?.vendorId?.isoNo}</span>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="d-flex align-items-center">
                                    <p className="mb-1 vendor-title font-weight-bold">CEO Adhar NO: </p>
                                    <span className="ml-2">{vendorData?.vendorId?.ceoAdharNo}</span>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="d-flex align-items-center">
                                    <p className="mb-1 vendor-title font-weight-bold">Operational Address: </p>
                                    <span className="ml-2">{vendorData?.vendorId?.operationalAddress}</span>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="d-flex align-items-center">
                                    <p className="mb-1 vendor-title font-weight-bold">GST Statement: </p>
                                    <a href={process.env.REACT_APP_BUCKET_URL + vendorData?.vendorId?.gstStatementFile} target="blank" className="ml-2">Download Statement</a>
                                </div>
                            </li>
                            {vendorData.documents && vendorData?.documents?.isDocSigned && <li className="list-group-item">
                                <div className="d-flex align-items-center">
                                    <p className="mb-1 vendor-title font-weight-bold">MSA: </p>
                                    <a href={process.env.REACT_APP_BUCKET_URL + vendorData?.documents?.unsignedMsa} target="blank" className="ml-2">Click to view MSA</a>
                                </div>
                            </li>}
                            <li className="list-group-item">
                                <div className="d-flex align-items-center">
                                    <p className="mb-1 vendor-title font-weight-bold">NDA/MSA Status: </p>
                                    <span href={process.env.REACT_APP_BUCKET_URL + vendorData?.documents?.isDocSigned} target="blank" className={`ml-2 badge ${vendorData?.documents?.isDocSigned == false ? `badge-danger` : `badge-success`}`}>{vendorData?.documents?.isDocSigned ? "Signed" : "Un-Signed"}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Modal.Body>
            {vendorData?.documents?.status !== "signed" && <Modal.Footer>
                {vendorData?.documents?.approvedByCompany !== 'approved' && vendorData?.status !== 'approved' &&
                    <>
                        <button onClick={() => clickToReject(vendorData?._id)} className="btn btn-danger btn-sm ml-2">
                            Reject
                        </button>
                        <button onClick={() => clickToApprove(vendorData?._id)} className="btn btn-success btn-sm ml-2">
                            Approve
                            {approveLoading && <span className="ml-1 spinner spinner-white"></span>}
                        </button>
                    </>}
                {/* {
            vendorData?.documents?.isDocSigned && vendorData?.documents?.approvedByCompany == "pending" && <> 
                <button onClick={() => clickToRejectDoc(vendorData?.documents?._id, vendorData?.documents?.vendorId)} className="btn btn-danger btn-sm ml-2">
                    Reject NDA/MSA
                </button>
                <button onClick={() => clickToApproveDoc(vendorData?.documents?._id, vendorData?.documents?.vendorId)} className="btn btn-success btn-sm ml-2">
                    Approve NDA/MSA
                </button>
            </>} */}
            </Modal.Footer>}
        </Modal>
    );
};

export default VendorModal;
