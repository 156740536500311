import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { FaFileInvoice } from "react-icons/fa6";
import { LuFileStack } from "react-icons/lu";
import { GrTechnology } from "react-icons/gr";
import { MdDashboard } from "react-icons/md";
import {checkIsActive } from "../../../../_helpers";
import { CompanyRoutesConfig } from "../../../../../app/companyRoutesConfig";
import useUserDetails from "../../../../../helperFunc/useUserDetails";

export function AsideMenuList({ layoutProps }) {
  const {userRole, permissions } = useUserDetails();
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {userRole !== "vendor" ? (
          <>
            {CompanyRoutesConfig.map((route, index) => {
              let showRoute = permissions.includes(route.permission)
              if ((route?.name !== "" && showRoute) || (route?.name !== "" && userRole == 'company')) {
                return (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      route.path,
                      false
                    )}`}
                    key={index}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to={route?.path}>
                      <span className="svg-icon menu-icon">
                        {route?.icon}
                      </span>
                      <span className="menu-text">{route?.name}</span>
                    </NavLink>
                  </li>
                );
              }
            })}
          </>
        ) : (
          <>
            <li
              className={`menu-item ${getMenuItemActive(
                "/vendor/dashboard",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/vendor/dashboard">
                <span className="svg-icon menu-icon">
                  <MdDashboard />
                </span>
                <span className="menu-text">Dashboard</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/vendor/applications",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/vendor/applications">
                <span className="svg-icon menu-icon">
                  <LuFileStack />
                </span>
                <span className="menu-text">My Applications</span>
              </NavLink>
            </li>
          </>
        )}
      </ul>
    </>
  );
}
