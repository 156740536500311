import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import toastNotification from "../../../../helperFunc/toast";
import { postReq } from "../../../api";
import Select from "react-select";

const AddRequirement = ({getRequirement, query, handleClose}) => {
  const initialValues = {
    requirementName: "",
    requirementJobDescription: "",
    requirementType: "",
    requirementNiche: [],
    requiredTechnology: [],
    budget: "",
    jobLocation: "",
    minExperience: "",
    maxExperience: ""
  };

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [requiredTechArray, setRequiredTechArray] = useState([]);
  const [techNiche, setTechNiche] = useState([]);
  const [selectedRequirementType, setSelectedRequirementType] = useState([]);

  const [requiredTechOptions, setRequiredTechOptions] = useState([]);
  const [techNicheOptions, setTechNicheOptions] = useState([]);

  const requirementTypeOptions = [
    {
      value: "Fte",
      label: "Fte",
    },
    {
      value: "Contractual",
      label: "Contractual",
    },
  ];

  const handleTagsData = async (isRequiredTech) => {
    try {
      let { _id } = JSON.parse(localStorage.getItem('companyDetails'));
      let type = (isRequiredTech) ? "requiredTech" : 'techNiche';

      const callResponse = await postReq("/common/getTags", { type: type, companyId: _id });

      if (callResponse.status == 1) {
        if (isRequiredTech) {
          setRequiredTechOptions(callResponse.data);
          console.log('call respnosea required tech ', callResponse.data)
        } else {
          setTechNicheOptions(callResponse.data)
          console.log('call response techNiche ', callResponse.data)
        }
      }

    } catch (error) {
      console.log('error ', error);
    }
  }

  const PostRequirementSchema = Yup.object().shape({
    requirementName: Yup.string()
      .required("Requirement Name is required"),
    requirementJobDescription: Yup.string()
      .min(100, "Minimum 100 characters required")
      .max(500, 'You have exceeded the 500 character limit!')
      .required("Job description is required"),
    requirementType: Yup.string().required("Requirement Type is required"),
    requirementNiche: Yup.array()
      .of(Yup.string())
      .min(1, "Requirement niche is required")
      .max(10, "You can add atmost 10 niches")
      .required("Requirement niche is required"),
    requiredTechnology: Yup.array()
      .of(Yup.string())
      .min(1, "Technology is required")
      .max(10, "You can add atmost 10 technologies")
      .required("Technology is required"),
    minExperience: Yup.string()
      .required("Minimum Experience is required")
      .min(1, 'Minimum Experience should be min 1 year')
      .max(15, 'Minimum Experience should be max 15 year')
      .matches(/^\d+$/, 'Minimum Experience must be a number'),
    maxExperience: Yup.string()
      .required("Maximum Experience is required")
      .min(2, 'Maximum Experience should be min 2 year')
      .max(30, 'Maximum Experience should be max 30 year')
      .matches(/^\d+$/, 'Maximum Experience must be a number'),
    budget: Yup.string()
      .required("Buget for role is required")
      .min(4, 'Budget is too low')
      .max(7, 'Budget is too high')
      .matches(/^\d+$/, 'Budget should be a number'),
    jobLocation: Yup.string()
      .min(3, "Job location must contains more than 3 characters")
      .required("Job location is required")
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleRequiredTech = (techData) => {
    if (techData !== null) {
      setRequiredTechArray(techData);
      let updatedTech = [];
      if (techData.length > 0) {
        techData.map((tech) => {
          updatedTech.push(tech._id);
        });
      }
      formik.setFieldValue("requiredTechnology", updatedTech);
    } else {
      formik.setFieldValue("requiredTechnology", []);
      setRequiredTechArray([]);
    }
  };

  const handleTechNiche = (techData) => {
    if (techData !== null) {
      setTechNiche(techData);
      let updatedTech = [];
      if (techData.length > 0) {
        techData.map((tech) => {
          updatedTech.push(tech._id);
        });
      }
      formik.setFieldValue("requirementNiche", updatedTech);
    } else {
      formik.setFieldValue("requirementNiche", []);
      setTechNiche([]);
    }
  };

  const handleRequirementType = (typeData) => {
    setSelectedRequirementType(typeData);
    formik.setFieldValue("requirementType", typeData.value);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const handleFormSubmission = async (data) => {
    try {
      const postData = await postReq("/company/requirement/create", data, {});
      if (postData.status == 1) {
        toastNotification("success", postData.message);
        formik.resetForm();
        setRequiredTechArray([]);
        getRequirement(query)
        disableLoading();
        handleClose();
      } else {
        toastNotification("error", postData.message);
        formik.resetForm();
        setRequiredTechArray([]);
        disableLoading();
        handleClose();
      }
    } catch (error) {
      toastNotification("error", "Something went wrong, Try again");
      disableLoading();
      handleClose();
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: PostRequirementSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      await handleFormSubmission(values);
    },
  });

  const backToDeviceList = () => {
    history.push(`/company/dashboard`);
  };
  useEffect(() => {
    handleTagsData(true);
    handleTagsData(false)
  }, [])
  return (
    <>
      <div className="login-form" style={{ display: "block" }}>
        <form
          id="kt_login_signin_form"
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          style={{
            background: "white",
          }}
        >
          <div className="row">
            <div className="col-md-6 col-lg-6 col-xs-12">
              <div className="form-group fv-plugins-icon-container">
              <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>Requirement Name <span style={{'color':"red"}}>*</span></p>
                <input
                  placeholder="Enter requirement name"
                  type="text"
                  className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
                    "requirementName"
                  )}`}
                  name="requirementName"
                  {...formik.getFieldProps("requirementName")}
                />
                {formik.touched.requirementName &&
                  formik.errors.requirementName ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.requirementName}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xs-12">
              <div className="form-group fv-plugins-icon-container">
              <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>Select Requirement Type <span style={{'color':"red"}}>*</span></p>
                <Select
                  placeholder="Select Requirement Type"
                  options={requirementTypeOptions}
                  onChange={(e) => handleRequirementType(e)}
                  name="requirementType"
                  onBlur={(e) => formik.handleBlur(e)}
                  value={selectedRequirementType}
                />
                {formik.touched.requirementType &&
                  formik.errors.requirementType ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.requirementType}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xs-12">
              <div className="form-group fv-plugins-icon-container">
              <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>Select Technology Niche <span style={{'color':"red"}}>*</span></p>
                <Select
                  getOptionLabel={option => option.value}
                  getOptionValue={option => option._id}
                  placeholder="Technology Niche"
                  options={techNicheOptions}
                  isMulti
                  onChange={(e) => handleTechNiche(e)}
                  name="requirementNiche"
                  onBlur={(e) => formik.handleBlur(e)}
                  value={techNiche}
                />
                {formik.touched.requirementNiche &&
                  formik.errors.requirementNiche ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.requirementNiche}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xs-12">
              <div className="form-group fv-plugins-icon-container">
              <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>Select Required Technology <span style={{'color':"red"}}>*</span></p>
                <Select
                  getOptionLabel={option => option.value}
                  getOptionValue={option => option._id}
                  placeholder="Required Technology"
                  options={requiredTechOptions}
                  isMulti
                  onChange={(e) => handleRequiredTech(e)}
                  name="requiredTechnology"
                  onBlur={(e) => formik.handleBlur(e)}
                  value={requiredTechArray}
                />
                {formik.touched.requiredTechnology &&
                  formik.errors.requiredTechnology ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.requiredTechnology}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xs-12">
              <div className="form-group fv-plugins-icon-container">
              <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>Enter Minimum Experience <span style={{'color':"red"}}>*</span></p>
                <input
                  placeholder="Enter Minimum experience required"
                  type="text"
                  className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
                    "minExperience"
                  )}`}
                  name="minExperience"
                  {...formik.getFieldProps("minExperience")}
                />
                {formik.touched.minExperience &&
                  formik.errors.minExperience ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.minExperience}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xs-12">
              <div className="form-group fv-plugins-icon-container">
              <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>Enter Maximum Experience <span style={{'color':"red"}}>*</span></p>
                <input
                  placeholder="Enter maximum experience required"
                  type="text"
                  className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
                    "maxExperience"
                  )}`}
                  name="maxExperience"
                  {...formik.getFieldProps("maxExperience")}
                />
                {formik.touched.maxExperience &&
                  formik.errors.maxExperience ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.maxExperience}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xs-12">
              <div className="form-group fv-plugins-icon-container">
              <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>Enter Job Location <span style={{'color':"red"}}>*</span></p>
                <input
                  placeholder="Enter Job location"
                  type="text"
                  className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
                    "jobLocation"
                  )}`}
                  name="jobLocation"
                  {...formik.getFieldProps("jobLocation")}
                />
                {formik.touched.jobLocation && formik.errors.jobLocation ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.jobLocation}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {/* <div className="col-md-6 col-lg-6 col-xs-12">
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Enter Required experience"
                  type="text"
                  className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
                    "requriedExperience"
                  )}`}
                  name="requriedExperience"
                  {...formik.getFieldProps("requriedExperience")}
                />
                {formik.touched.requriedExperience &&
                formik.errors.requriedExperience ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.requriedExperience}
                    </div>
                  </div>
                ) : null}
              </div>
            </div> */}
            <div className="col-md-6 col-lg-6 col-xs-12">
              <div className="form-group fv-plugins-icon-container">
              <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>Enter Budget <span style={{'color':"red"}}>*</span></p>
                <input
                  placeholder="Enter budget"
                  type="text"
                  className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
                    "budget"
                  )}`}
                  name="budget"
                  {...formik.getFieldProps("budget")}
                />
                {formik.touched.budget &&
                  formik.errors.budget ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.budget}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-xs-12">
              <div className="form-group fv-plugins-icon-container">
              <p className="form-label-rmt" style={{ marginBottom: "0.5rem" }}>Enter Job Description <span style={{'color':"red"}}>*</span></p>
                <textarea
                  placeholder="Enter job description"
                  type="requirementJobDescription"
                  className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
                    "requirementJobDescription"
                  )}`}
                  name="requirementJobDescription"
                  rows={7}
                  {...formik.getFieldProps("requirementJobDescription")}
                />
                <p className="text-danger text-right mb-1">Max - 500 </p>
                {formik.touched.requirementJobDescription &&
                  formik.errors.requirementJobDescription ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.requirementJobDescription}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12 col-xs-12">
              <div className="form-group d-flex flex-wrap flex-center">
                <button
                  type="button"
                  disabled={!(formik.dirty && formik.isValid)}
                  onClick={() => formik.handleSubmit()}
                  className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  <span>Submit</span>
                  {loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </button>
                <button
                  type="button"
                  onClick={() => formik.resetForm()}
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddRequirement;
