import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
// import {DashboardPage} from "./pages/DashboardPage";
// import CompanyHome from "./pages/Company/CompanyHome";
import ViewApplications from "./pages/Company/ViewApplications/index";
import VendorHome from "./pages/Vendor/VendorHome";
import JobDetails from "./pages/Vendor/JobDetails/JobDetails";
import MyApplications from "./pages/Vendor/MyApplication";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);

export default function BasePage(props) {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* Redirect from root URL to /dashboard. */
          props.userRole == 'company' ? (
            <Redirect exact from="/" to="/company/dashboard" />
          ) : props.userRole == 'vendor' ? (
            <Redirect exact from="/" to="/vendor/dashboard" />
          ) : (
            <Redirect exact from="/" to="/company/dashboard" />
          )}
        {props.CompanyRoutesConfig.length > 0 ? (
          <>
            {props.CompanyRoutesConfig.map((route, index) => (
              <ContentRoute
                path={route.path}
                component={route.component}
                key={index}
              />
            ))}

            <ContentRoute 
              path="/vendor/dashboard" 
              component={VendorHome} 
            />

            <ContentRoute
              path="/vendor/job-details/:id"
              component={JobDetails}
            />

            <ContentRoute
              path="/vendor/applications"
              component={MyApplications}
            />

          </>
        ) : (
          <>
          </>
        )}
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
