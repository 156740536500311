import React, { useEffect, useState } from "react";
import { postReq } from "../../../api";
import Loading from "../../../components/Loading";
import ReusableTable from '../../../components/ReusableTable';
import {
    Card,
    CardBody
} from "../../../../_metronic/_partials/controls";
import { sortCaret } from "../../../../_metronic/_helpers";
import VendorModal from "../../../components/vendorModal";

const Vendors = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [vendorsData, setVendorsData] = useState([]);
    const [vendor, setVendor] = useState("");
    const [datatableData, setDatatableData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [filters, setFilters] = useState({
      options: {
        page: 1,
        limit: 10,
      },
      query: {
        search: "",
      },
    });

    // update pagination options
    const updatepaginationOptions = (options) => {
      let tempFilters = { ...filters };
      tempFilters.options.page = options.page;
      tempFilters.options.limit = options.sizePerPage;
      setFilters(tempFilters);
    };
    
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const vendorsList = await postReq("/company/get-vendor-list", "");
            if (vendorsList.status == 1) {
                let tempData = [];
                vendorsList.data &&  vendorsList.data.length > 0 && vendorsList.data.map((data, i) => {
                    if (data?.vendorId !== null) {
                        let jsonData = {
                            vendorId: data?.vendorId?._id,
                            name: data?.vendorId?.name,
                            email: data?.vendorId?.vendorEmail,
                            companyURL: data?.vendorId?.vendorCompanyWebsiteUrl,
                            companyStrength: data?.vendorId?.vendorCompanyStrength,
                            status: data?.status
                        };
                        tempData.push(jsonData);
                    }
                });
                setVendorsData(vendorsList.data);
                setDatatableData(tempData);
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    function getFirstCharsFromString(str) {
        if (str.includes(' ')) {
            // Split the string by spaces
            const words = str.split(' ');
            // Extract the first character of each word and join them
            return words.map(word => word.charAt(0)).join('');
        } else {
            // If there are no spaces, return the first two characters
            return str.substring(0, 2);
        }
    }

    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
            sortCaret: sortCaret,
            headerStyle: { width: '200px' },
            style: { width: '200px' },
            formatter: (cellContent, row) => {
                return <div><span className="border-radius-50 symbol symbol-light-success symbol-45">
                    <span className="symbol-label font-weight-bolder font-size-h6 border-radius-50 text-uppercase">
                    {getFirstCharsFromString(row.name)}
                    </span>
                </span>
                <span className="ml-2">{row.name}</span>
                </div>
            }
        },
        {
            dataField: 'email',
            text: 'Company Email',
            sort: true,
            headerStyle: { width: '250px' },
            style: { width: '250px' },
            sortCaret: sortCaret,
            formatter: (cellContent, row) => {
                return <a href={`mailto:${row.email}`}>{row.email}</a>
            }
        },
        {
            dataField: 'companyURL',
            text: 'Company URL',
            sort: true,
            formatter: (cellContent, row) => {
                return <a href={row.companyURL} target="blank">{row.companyURL}</a>
            }
        },
        {
            dataField: 'companyStrength',
            text: 'Company Strength',
            sort: true,
            sortCaret: sortCaret,
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            sortCaret: sortCaret,
            headerStyle: { width: '120px' },
            style: { width: '120px' },
            formatter: (cellContent, row) => {
                if (row.status == "pending") {
                    return <span className="badge badge-warning">{row.status}</span>
                } else if (row.status == "approved") {
                    return <span className="badge badge-success">{row.status}</span>
                } else if (row.status == "reject") {
                    return <span className="badge badge-danger">{row.status}</span>
                }
            }
        },
        {
            dataField: 'actions',
            text: 'Actions',
            headerStyle: { width: '160px' },
            style: { width: '160px' },
            formatter: (cellContent, row) => {
                return (
                    <div>
                        <button onClick={() => viewDetails(row.vendorId)} className="btn btn-info btn-sm ml-2">
                            View Details
                        </button>
                    </div>
                );
            },
        }
    ];

    const viewDetails = (id) => {
        const vendorData = vendorsData.find(item => item?.vendorId?._id == id);
        setVendor(vendorData);
        setShowModal(true)
    };

    const handleClose = () => setShowModal(false);
    
    return (
        <>
            {showModal && (
                <VendorModal
                    show={showModal}
                    vendorData={vendor}
                    handleClose={handleClose}
                />
            )}
            <Card>
                <CardBody>
                    <ReusableTable
                        columns={columns}
                        data={datatableData}
                        totalSize={datatableData !== "" ? datatableData.length : 0}
                        updatepaginationOptions={updatepaginationOptions}
                        keyField="vendor-list"
                        isLoading={isLoading}
                    />
                </CardBody>
            </Card>
        </>
    );
};

export default Vendors;