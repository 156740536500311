import React, {useEffect, useCallback, useState} from "react";
import {withRouter} from 'react-router-dom';
import _ from 'lodash';
import JobView from "../../components/JobComponent";
import { postReq } from "../../api";
import toastNotification from "../../../helperFunc/toast";
import Loading from "../../components/Loading";


const VendorHome = ()=> {
    const [query, setQuery] = useState({
        page: 1, 
        limit: 10,
        searchParams: ""
    });
    const [jobData, setJobData] = useState([]);
    const [isLoading, setIsLoading] = useState();

    const colorsArray = [
        "#3699FF",
        "#195ea3",
        "#6b6bf5"
    ];

    function getRandomColorFromArray(colors) {
        // Generate a random index based on the length of the colors array
        const randomIndex = Math.floor(Math.random() * colors.length);
        // Return the color at the random index
        return colors[randomIndex];
    }

    const updateData = async (data)=> {
        let newData = [];
        if (data.length > 0) {
            await data.map((element)=> {
                let updatedElement = {...element};
                updatedElement.isCompany = false;
                updatedElement.color = getRandomColorFromArray(colorsArray);
                newData.push(updatedElement);
            });
        }
        return newData
    }

    let getRequirementList = async (query)=> {
        try {
            setIsLoading(true);
            const getRes = await postReq("/vendor/requirement/list", query);
            
            if (getRes.status == 1) {
                let newData = await updateData(getRes.data.requirements);
                setJobData(newData);
                // toastNotification('success', getRes.message);
                setIsLoading(false)
            } else {
                setJobData([]);
                //toastNotification('error', getRes.message);
                setIsLoading(false)
            }
        } catch (error) {
            setJobData([]);
            toastNotification("error", "Something went wrong");
            setIsLoading(false)
        }
    }

    useEffect(()=> {
        getRequirementList(query);
    }, [])

    const handleOnChange = (event) => {
        const value = event.target.value;
        console.log('valie ', value)
        let data = {...query}
        data.searchParams = value
        searchData(data);
    }

    const searchData = useCallback(
        _.debounce((value) => {
            console.log('Searching for:', value);
            getRequirementList(value);
        }, 500), // 500ms debounce time
        []
    );

    return (
        <>
            <div className="vendorDashboard">
                <div className="row justify-content-center searchId">
                    <div className="col-md-4 col-lg-4 col-xs-12 mt-2 mb-5">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Search By Job ID" onChange={handleOnChange} />
                            <div className="input-group-append newBackColor">
                            <button className="btn btn-primary" type="button">
                                <i className="fa fa-search"></i>
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center vendorWrapperId">
                    {!isLoading ? (
                        jobData.length > 0 ? (
                            jobData.map((data, index)=> (
                                <JobView {...data} />
                            ))
                        ) : (
                            <div> No requirements found!!</div>
                        )
                    ): <Loading/>}
                </div>
            </div>
        </>
    )
}

export default withRouter(VendorHome);