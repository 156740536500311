import React, { useState, useEffect } from "react";
import {Spinner, Button} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import toastNotification from "../../../helperFunc/toast";
import { postReq } from "../../api";
import * as Yup from "yup";
import { useFormik, Field } from "formik";

const AddRoleModal = ({ show, companyRoutesConfig, handleClose }) => {
    const [loading, setLoading] = useState(false);
    const initialValues = {
        name: "",
        permissions: []
    };

    const applySchema = Yup.object().shape({
        name: Yup.string()
          .required("Role is required"),
        permissions: Yup.array()
        .min(1, 'Select at least one permission')
        .required('Select at least one permission')
    });

    const formik = useFormik({
        initialValues,
        validationSchema: applySchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            try {
                setLoading(true);
                const addRole = await postReq("/company/create-role", values);
                if (addRole.status == 1) {
                    toastNotification("success", addRole.message);
                    setTimeout(() => {
                        formik.resetForm();
                        setLoading(false);
                        handleClose();
                    }, 2000);
                } else {
                    toastNotification("error", addRole.message);
                    setTimeout(() => {
                        formik.resetForm();
                        setLoading(false);
                        handleClose();
                    }, 2000);
                }
            } catch (error) {
                toastNotification("error", error.message);
            }
        },
    });

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        const { permissions } = formik.values;
    
        if (checked) {
          formik.setFieldValue('permissions', [...permissions, value]);
        } else {
          formik.setFieldValue('permissions', permissions.filter((perm) => perm !== value));
        }
      };

    return(
        <Modal
            show={show}
            className="apply-job-modal"
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add New Role
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Role</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Role"
                            value={formik.values.name}
                            onChange={(e) => formik.setFieldValue("name", e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Permissions</Form.Label>
                        <div className="row">
                            {companyRoutesConfig.map((permissionData, index) => (
                                <div className="col-md-4 col-lg-4 col-xs-6 d-flex" key={index}>
                                    <input
                                    type="checkbox"
                                    className="permission-checbox"
                                    id={permissionData.permission}
                                    name="permissions"
                                    value={permissionData.permission}
                                    onChange={handleCheckboxChange}
                                    checked={formik.values.permissions.includes(permissionData.permission)}
                                    />
                                    <label htmlFor={permissionData.permission} className="ml-2">{permissionData.permission}</label>
                                </div>
                            ))}
                            <div className="col-md-12 col-lg-12 col-xs-12">
                                {formik.errors.permissions && formik.touched.permissions && (
                                    <div className="text-danger">{formik.errors.permissions}</div>
                                )}
                            </div>
                        </div>
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-4">
                        <Button
                            className={`btn btn-primary font-weight-bold ${loading ? 'px-9' : 'px-4'}`}
                            disabled={!(formik.dirty && formik.isValid)}
                            onClick={() => formik.handleSubmit()}
                            variant="primary"
                            type="button">
                            Submit
                            {loading && (
                                <span className="ml-2 spinner spinner-white"></span>
                            )}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddRoleModal;
