import React from "react";
import CompanyHome from "./pages/Company/CompanyHome";
import { Demo1Dashboard } from "../_metronic/_partials/dashboards/Demo1Dashboard";
import JobDetailsCompany from "./pages/Company/JobDetails/index";
import AddRequirement from "./pages/Company/AddRequirement";
import selectedCandiadtes from "./pages/Company/SelectedCandidates";
import EditRequirement from "./pages/Company/EditRequirement";
import Vendors from "./pages/Company/Vendors";
import ComplianceDocuments from "./pages/Company/ComplianceDocuments";
import Invoice from "./pages/Company/Invoice";
import TechnologyNiche from "./pages/Company/TechnologyNiche";
import Roles from "./pages/Company/Roles";
import Users from "./pages/Company/Users";
import Timesheet from "./pages/Company/Timesheet";
import AddTimesheet from "./pages/Company/Timesheet/Add";
// icons
import { MdDashboard } from "react-icons/md";
import { FaWpforms } from "react-icons/fa";
import { IoDocuments } from "react-icons/io5";
import { FaUserCheck } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { FaFileInvoice } from "react-icons/fa6";
import { FaUserShield } from "react-icons/fa";
import { FaUsersGear } from "react-icons/fa6";
import EditTimesheet from "./pages/Company/Timesheet/Edit";
import ViewApplications from "./pages/Company/ViewApplications";

export const CompanyRoutesConfig = [
    {
        path: "/company/dashboard",
        name: "Dashboard",
        component: Demo1Dashboard,
        permission: "view_dashboard",
        icon: <MdDashboard />
    },
    {
        path: "/company/roles",
        name :"Roles",
        component: Roles,
        permission: "view_roles",
        icon: <FaUsersGear />
    },
    {
        path: "/company/users",
        name :"Users",
        component: Users,
        permission: "view_users",
        icon: <FaUserShield />
    },
    {
        path: "/company/requirements",
        name: "Requirements",
        component: CompanyHome,
        permission: "view_requirements",
        icon: <FaWpforms />
    },
    {
        path: "/company/job-details/:id",
        name :"",
        component: JobDetailsCompany,
        permission: "view_jobdetails",
    },
    {
        path: "/company/requirement/add",
        name :"",
        component: AddRequirement,
        permission: "add_jobdetails",
    },
    {
        path: "/company/view-responses/:id",
        name :"",
        component: ViewApplications,
        permission: "view_responses",
    },
    {
        path: "/company/consultants",
        name: "Consultants",
        component: selectedCandiadtes,
        permission: "view_candiadtes",
        icon: <FaUserCheck />
    },
    {
        path: "/company/edit-job-details/:id",
        name :"",
        component: EditRequirement,
        permission: "view_edit_requirement",
    },
    {
        path: "/company/vendors",
        name :"Vendors",
        component: Vendors,
        permission: "view_vendors",
        icon: <FaUsers />
    },
    {
        path: "/company/compliance-documents",
        name :"Compliance Documents",
        component: ComplianceDocuments,
        permission: "view_compliance_documents",
        icon: <IoDocuments />
    },
    {
        path: "/company/invoice",
        name :"Invoices",
        component: Invoice,
        permission: "view_invoices",
        icon: <FaFileInvoice />
    },
    {
        path: "/company/technology-niche",
        name :"",
        component: TechnologyNiche,
        permission: "view_technology_niche",
    },
    {
        path: "/company/timesheets",
        name :"Timesheet",
        component: Timesheet,
        permission: "view_timesheet",
        icon: <IoDocuments />
    },
    {
        path: "/company/timesheet/add",
        name :"",
        component: AddTimesheet,
        permission: "add_timesheet"
    },
    {
        path: "/company/timesheet/edit/:id",
        name :"",
        component: EditTimesheet,
        permission: "edit_timesheet"
    }
];