import { toast } from "react-toastify";

const toastNotification = (type, text)=> {
    switch (type) {
        case "success":
            return toast.success(text)
            break;
        case "info": 
            return toast.info(text)
            break;
        case 'warning':
            return toast.warn(text)
            break;
        case 'error':
            return toast.error(text)
            break;
        default:
            return toast(text)
            break;
    }
}

export default toastNotification;