import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import toastNotification from "../../../../helperFunc/toast";
import post from "../api";
import { useHistory, withRouter } from 'react-router-dom'
import { InputGroup } from "react-bootstrap";
import IconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function CompanyLogin() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  let history = useHistory();
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        "Company email is required to login"
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        "Password is reuiqred to login"
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const companyLogin = async (data) => {
    try {
        const login = await post('/company/login', data, {});
        if (login.status == 1) {
            toastNotification('success', login.message);
            localStorage.setItem('companyDetails', JSON.stringify(login.data.userData));
            localStorage.setItem('companyToken', login.data.token);
            localStorage.setItem('userRole', 'company');
            disableLoading();
            formik.resetForm();
            setTimeout(() => {
                window.location.replace("/")
            }, 1000);
        } else {
            console.log('login ', login)
            toastNotification('error', login.message);
            disableLoading();
            formik.resetForm();
        }
    } catch (error) {
        console.log('error , ', error);
        toastNotification('error', "Something went wrong, try again");
        disableLoading();
        formik.resetForm()
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      await companyLogin(values)
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          Company Login 
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
        <InputGroup>
          <input
            placeholder="Password"
            type={showPassword ? 'text' : 'password'}
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          <InputGroup.Prepend className="get-cursor-pointer" onClick={togglePasswordVisibility}>
              <InputGroup.Text id="basic-addon2">
                <IconButton color="primary" aria-label="upload picture" component="span" className="p-0">
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputGroup.Text>
            </InputGroup.Prepend>
          </InputGroup>
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            Forgot Password?
          </Link>
          <button
            id="kt_login_signin_submit"
            type="button"
            disabled={formik.isSubmitting || !(formik.dirty && formik.isValid)}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            onClick={()=> formik.handleSubmit()}
          >
            <span>Login</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default withRouter(CompanyLogin);
