import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import toastNotification from "../../../../helperFunc/toast";
import { InputGroup } from "react-bootstrap";
import IconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import post from "../api";

const initialValues = {
  email: "",
  password: "",
};

function VendorLogin() {
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        "Vendor email is required to login"
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        "Password is reuiqred to login"
      ),
  });

  const [showPassword, setShowPassword] = useState(false);
  const enableLoading = () => {
    setLoading(true);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const vendorLogin = async (data) => {
    try {
        const login = await post('/vendor/login', data, {});

        if (login.status == 1) {
            toastNotification('success', login.message);
            disableLoading();
            formik.resetForm();
            localStorage.setItem("userRole", "vendor");
            localStorage.setItem("vendorToken", login.data.token);
            localStorage.setItem('vendorDetails', JSON.stringify(login.data.userData));
            setTimeout(() => {
                window.location.reload()
            }, 1000);
        } else {
            toastNotification('error', login.message);
            disableLoading();
            formik.resetForm();
        }
    } catch (error) {
        console.log('error , ', error);
        toastNotification('error', "Something went wrong, try again");
        disableLoading();
        formik.resetForm()
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      await vendorLogin(values)
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          Vendor Login 
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <InputGroup>
          <input
            placeholder="Password"
            type={showPassword ? 'text' : 'password'}
            className={`form-control form-control-solid h-auto py-3 px-4 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          <InputGroup.Prepend onClick={togglePasswordVisibility} className="get-cursor-pointer">
              <InputGroup.Text id="basic-addon2">
                <IconButton color="primary" aria-label="upload picture" component="span" onClick={togglePasswordVisibility} className="p-0">
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputGroup.Text>
            </InputGroup.Prepend>
          </InputGroup>
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            Forgot Password?
          </Link>
          <button
            id="kt_login_signin_submit"
            type="button"
            onClick={()=> formik.handleSubmit()}
            disabled={formik.isSubmitting || !(formik.dirty && formik.isValid)}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Login</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default VendorLogin
